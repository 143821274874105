import React, {useState} from 'react'
import Button from '../Button/button'
import './wordbuttons.css'

export default function Wordbuttons({ words40, handleOkButton, userAnswerArray, handleUserWordsInput }) {
  let [amountWordsPressed, setAmountWordsPressed] = useState(0)
  const [hasChosen15Words, setHasChosen15Words] = useState(false)  //This state is above Button.js component. This is because when reaching 15 word array length, other buttons that are not re-rendered at that moment don't know about this so they remain clickable. By setting the state one level above all buttons they all get re-rendered with the latest length of the word array on every click of button. In essence a button click rerenders all buttons again, instead that single button only.


  const handleNumberWordsPressed = () => {
    setAmountWordsPressed(userAnswerArray.length)
  }


  // const buttonsElements = words40.map(word => {
  //   return <Button key={word.id} word={word} handleNumberWordsPressed={handleNumberWordsPressed}userAnswerArray={userAnswerArray} hasChosen15Words={hasChosen15Words} setHasChosen15Words={setHasChosen15Words} handleUserWordsInput={handleUserWordsInput}/>
  // })


  const buttonsElements = words40.map(word => {
    return <Button key={word} word={word} handleNumberWordsPressed={handleNumberWordsPressed}userAnswerArray={userAnswerArray} hasChosen15Words={hasChosen15Words} setHasChosen15Words={setHasChosen15Words} handleUserWordsInput={handleUserWordsInput}/>
  })


  return (
      <>
        {/* <h3>最初に表示された単語を選んでください</h3> */}
        <div className="wordbuttons__buttons-pad">
          {buttonsElements}
        </div>

        <div className='wordbuttons__ok-button-container'>
          {/* {amountWordsPressed === 15 && <h3>あとでまた、これらの単語を尋ねますので覚えておいてください。</h3>} */}
          {amountWordsPressed === 15 && <button className='wordbuttons__ok-button' onClick={()=> {handleOkButton()}}>OK</button>}
        </div>

        <div className="wordbuttons__selected-button-number-display">
          <span>{amountWordsPressed}/15</span>
        </div>

      </>
    )

}
