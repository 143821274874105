import React, { useState, useRef, useEffect } from "react";
import "./stroop-partb-test.css";
import TestInstructions from "../TestInstructions/TestInstructions";
import TestCompleted from "../TestCompleted/TestCompleted";
import ProgressBar from "../../../ProgressBar/ProgressBar";
import HeaderLogo from '../../../HeaderLogo/HeaderLogo'
let testProgressBar = 1;

export default function StroopPartBTest() {
  let [currentKanji, setCurrentKanji] = useState("みどり");
  let [kanjiColor, setKanjiColor] = useState({ color: "red" });
  let [correctScore, setCorrectScore] = useState(0);
  let [incorrectScore, setIncorrectScore] = useState(0);
  let [currentRound, setCurrentRound] = useState(1);
  const [showInstructions, setShowInstructions] = useState(true);
  const [testFinished, setTestFinished] = useState(false);
  const [timer, setTimer] = useState(60);
  let colorRandom = useRef();
  let kanjiRandom = useRef();
  const kanjis = {
    blue: "あお",
    yellow: "きいろ",
    black: "くろ",
    green: "みどり",
    red: "あか",
  };
  const colors = ["blue", "yellow", "black", "green", "red"];
  let testScore = useRef(0);

  useEffect(() => {
    startRound();
    // return () => {
    //   cleanup
    // }
    // eslint-disable-next-line
  }, []);

  const saveScoreOnLocalStorage = (score) => {
    localStorage["StroopScore"] = JSON.stringify(score);
  };

  //testProgressPage = localStorage.getItem("onPage");

  useEffect(() => {
    if (timer === 0 && showInstructions === false) {
      //Prevents time running out and test ending while Instructions are displayed.
      endTest();
    }
    if (timer > 0 && testFinished === false) {
      // Stops countdown on the Otsukaresama page.
      // console.log("Timer", timer)
      const countdown = () => setTimeout(() => setTimer(timer - 1), 1000);
      const timerId = countdown();
      return () => {
        clearTimeout(timerId);
      };
    }
    // eslint-disable-next-line
  }, [timer]);

  const hideInstructions = () => {
    setShowInstructions(false);
    setTimer(60);
  };

  const endTest = () => {
    setTestFinished(true);
    //console.log("Part B TEST if finished")
  };

  const randomizeKanji = (kanjisDict) => {
    //Choose a random kanji from kanjis array
    let keys = Object.keys(kanjisDict);
    const randomKanji = kanjisDict[keys[(keys.length * Math.random()) << 0]];
    return randomKanji;
  };

  const randomizeColor = (colorsArray) => {
    //Choose a random color from colors array
    const randomColor =
      colorsArray[Math.floor(Math.random() * colorsArray.length)];
    return randomColor;
  };

  const applyColorToKanji = () => {
    const no_prev_color = colors.filter((e) => e !== kanjiColor["color"]);
    colorRandom.current = randomizeColor(no_prev_color);
    const currentColor = colorRandom.current;
    setKanjiColor({ color: currentColor });
    //console.log("currentColor", currentColor)

    const kanji_with_different_color = Object.keys(kanjis).reduce(
      (accumulator, key) => {
        // Copy all except current color and current kanji, to avoid overlap of meaning and color, and avoid previous kanji
        if (key !== currentColor && kanjis[key] !== currentKanji) {
          accumulator[key] = kanjis[key];
        }
        return accumulator;
      },
      {}
    );

    kanjiRandom.current = randomizeKanji(kanji_with_different_color);
    setCurrentKanji(kanjiRandom.current);
  };

  const handleAnswerClick = (selectedAnswer) => {
    //console.log("SelectedAnswer", selectedAnswer)
    //console.log("colorRandom.current in handle", colorRandom.current)
    if (colorRandom.current === selectedAnswer) {
      setCorrectScore((correctScore = correctScore + 1));
      saveScoreOnLocalStorage(testScore.current = testScore.current + 1)
    } else {
      setIncorrectScore((incorrectScore = incorrectScore + 1));
    }
    ////console.log("CorrectScore", correctScore)
    //console.log("incorrectScore", incorrectScore)
    setCurrentRound((currentRound = currentRound + 1));
    if (currentRound > 100) {
      endTest();
    }
    //console.log("Current round", currentRound)
    startRound();
  };

  const startRound = () => {
    applyColorToKanji();
  };

  return (
    <>
      <HeaderLogo />
      {testFinished ? (
        <TestCompleted
          correctScore={correctScore}
          incorrectScore={incorrectScore}
          currentRound={currentRound}
        />
      ) : showInstructions ? (
        <TestInstructions hideInstructions={hideInstructions} />
      ) : (
        <>
          <ProgressBar testProgressBar={testProgressBar} />
          {/* <div className="head welcome-text-button-results-container">
        <div className="welcome-text-container">
          <h1>注意力テスト（後半）</h1>
        </div>
      </div> */}

          <div className="score-info ">
            <span>
              {/* {user.is_admin && <div className = "total-score ">Score: <span style={{ color: 'blue' }}>{ correctScore } </span>| <span style={{ color: 'red' }}>{incorrectScore} </span></div>}
          {user.is_admin && <div className = "total-games">ラウンド: { currentRound } / 100</div>} */}
              <div className="total-games">残り時間: {timer}</div>
            </span>
          </div>
          <div>
            <div className="kanji-style" style={kanjiColor}>
              {currentKanji}
            </div>
          </div>
          <div className="buttonsContainer">
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("blue")}
              >
                あお
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("yellow")}
              >
                きいろ
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("black")}
              >
                くろ
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("green")}
              >
                みどり
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("red")}
              >
                あか
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
