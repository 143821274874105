import React from 'react';

const TermsModal = ({ onClose }) => {
  return (
    <>
      <div className="modal-overlay" onClick={onClose} />
      <div className="modal-container">
        <div className="modal-header" style={{ position: "sticky", top: 0, left: 0 }}>
          <div className="modal-close" onClick={onClose}>
            <div className="close-button"></div>
          </div>
          <p className="terms-of-use-modal-header">利用規約</p>
        </div>
          <div className="modal-body">
            <p>
              第１章　当社サービスの利用方法<br></br>
              <br></br>
              第1条　（適用） <br></br>
              1. この利用規約（以下「本規約」といいます。）は、株式会社CogSmart（以下「当社」といいます。）が提供する、次に掲げるサービス（「脳の健康３分チェック」その他当社指定の名称をつけることがあります。以下、総称して「本サービス」といいます。）の利用に対して、適用されます。<br></br>
              (1) 認知機能テストの受検サービス <br></br>
              (2) 上記(1)のサービスと一連のサービス、又は関連し若しくは付随するサービスとして、当社が提供するもの<br></br>
              2. 本サービスを利用する受診者（以下「受診者」といいます。）は本規約に同意のうえ、本サービスをご利用いただくことができます。なお、本規約への同意手続きに代えて、実際に本サービスをご利用いただく場合にも、本規約に同意いただいたものとみなします。<br></br>
              3. 当社が本サービス上に掲載又は提示・提供する諸注意等が存在する場合、その形式に拘らず、当該諸注意等は本規約の一部を構成するものとします。<br></br>
              <br></br>
              第2条　（受診者の責任）<br></br>
              1. 受診者は、自らの意思及び責任において、本サービスの利用又は当社への登録を行うものとします。<br></br>
              2. 本サービスでは、インターネット接続環境のあるPC、タブレット端末又はスマートフォン端末をご用意いただく必要があります。受診者は、自己の費用と責任において、本サービスの提供を受けるために必要なインターネット接続環境のある上記機器を用意するものとします。<br></br>
              3. 本サービスを受検するにあたり、受診者によっては過度のストレスを伴うものや、気分を悪くされるものを含む場合があります。この場合、受診者は、回答をしない、一度テストを中断して体調が回復したら再度受検する、回答を放棄して全てのテストを完了する等の対応が可能です。但し、これらの対応をした場合、本サービスを正確又は適切に提供できなくなる場合があります。当該対応により受診者に発生した不利益又は損害について、当社は一切の責任を負わないものとします。<br></br>
              <br></br>
              第2章　個人情報及びデータの取扱い<br></br>
              <br></br>
              第3条　（個人情報の取扱い）<br></br>
              1.	受診者が本サービスを利用するにあたり、当社は次の情報の一部又は全てを取得する場合があります（以下「個人情報」といいます。）。<br></br>
              ①	氏名<br></br>
              ②	年齢<br></br>
              ③	性別<br></br>
              ④	生年月日<br></br>
              ⑤	Eメールアドレス<br></br>
              ⑥	本サービスの受検結果<br></br>
              2.	当社は前項により取得した受診者の個人情報を以下の利用目的にのみ使用し、その他の目的では使用いたしません。 <br></br>
              ①	受診者ご本人へ本サービスを提供するため <br></br>
              ②	受診者からのご質問・ご相談・お問い合わせ等に対応するため<br></br>
              ③	受診者からご意見・ご感想をいただくため <br></br>
              ④	認知症その他脳疾患予防や脳健康の維持・向上に関する、当社商品・サービスの改善若しくは新商品・新サービスの開発（例えば、認知症予防を促す認知症保険商品や、生活習慣改善促進サービスなど、認知症その他脳疾患予防や脳健康の維持・向上に関する新商品・新サービスの開発を含みます。）、学術研究又はデータ利活用ビジネスへの活用のため <br></br>
              ⑤	会計監査その他法令に基づく監査の確認作業のため <br></br>
              ⑥	その他上記に掲げる利用目的に付帯・関連する事項のため <br></br>
              3.	当社は、取り扱う個人データの漏洩、滅失又は毀損の防止その他の個人データの安全管理のために必要かつ適切な措置を講じます。<br></br>
              4.	保有個人データ等の開示、訂正等、利用停止等<br></br>
              ①	当社は、受診者から、個人情報保護法の定めに基づき保有個人データ若しくは第三者提供記録の開示又は保有個人データの利用目的の通知を求められたときは、受診者ご本人からの請求であることを確認した上で、遅滞なく開示又は通知いたします。但し、個人情報保護法その他の法令により、当社が開示又は通知する義務を負わない場合はこの限りではありません。<br></br>
              ②	当社は、受診者から、個人情報保護法の定めに基づき保有個人データの内容が事実でないとして当該保有個人データの内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められたときは、受診者ご本人からの請求であることを確認した上で、遅滞なく必要な調査を行い、その結果に基づき、訂正等を行います。但し、個人情報保護法その他の法令により、当社が訂正等を行う義務を負わない場合はこの限りではありません。<br></br>
              ③	当社は、受診者から、個人情報保護法の定めに基づき、保有個人データの利用停止、消去又は第三者提供の停止（以下「利用停止等」といいます。）を求められたときは、受診者ご本人からの請求であることを確認した上で、遅滞なく利用停止等を行います。但し、個人情報保護法その他の法令により、当社が利用停止等を行う義務を負わない場合はこの限りではありません。<br></br>
              ④	前３号に定めるお求めをご希望いただく場合は、次項に記載の【お問合せ窓口】宛までご連絡ください。<br></br>
              5.	当社では、個人情報を適切に保護するための管理者を下記お問合せ窓口の個人情報保護管理者が担当しております。個人情報管理に関するお問い合わせや照会、訂正又は削除等の依頼は、下記までご連絡ください。<br></br>
              【お問合せ窓口】<br></br>
              株式会社Cogsmart 個人情報お問合せ窓口 <br></br>
              個人情報保護管理者 取締役 樋口彰 <br></br>
              〒102-0093 東京都千代田区平河町1丁目6番4号 <br></br>
              Eメール: info@cogsmart-global.com<br></br><br></br>
              6.	Cookie、広告識別子（IDFA/AAID）、Googleアナリティクス<br></br>
              ①	当社では、サービスのご利用状況を把握し、サービスの利便性を向上するため、またユーザーに適した広告配信等のために、Cookie、広告識別子（IDFA/AAID※）を使用し、または類似の技術を利用する場合があります。これらの情報に特定の個人を識別する情報は含まれていません。<br></br>
              ②	当社は、サービスのご利用状況を把握し、サービスの利便性を向上するために、Google社が提供するGoogle Analyticsを利用する場合があります。Google AnalyticsはCookie等を利用して、特定の個人を識別する情報を含まないかたちで閲覧履歴を収集、分析し、当社はその結果を受け取り、ユーザー（当社ウェブサイトの訪問者を含みます。）の利用状況を把握することで、サービスの開発、改善等に利用する場合があります。<br></br>
              Google Analyticsの無効設定は、以下のGoogle Analyticsオプトアウトアドオンからブラウザのアドオン設定を変更することで可能となります。なおGoogle Analyticsによって収集される情報はGoogle社のプライバシーポリシーに基づいて管理されます。<br></br>
              Googleアナリティクス利用規約<br></br>
              https://www.google.com/analytics/terms/jp.html<br></br>
              Googleプライバシーポリシー<br></br>
              https:// www.google.com/intl/ja/policies/privacy/<br></br>
              Google Analyticsオプトアウトアドオン<br></br>
              https://tools.google.com/dlpage/gaoptout?hl=ja<br></br>
              Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用<br></br>
              https://policies.google.com/technologies/partner-sites?hl=ja<br></br>
              <br></br>
              ※Cookieとは、ブラウザでウェブサイトを閲覧した際に作成される、利用履歴や入力内容などのデータを一時的に保管しておく仕組みを指します。<br></br>
              IDFA (Identifier for Advertisers)とAAID (Google Advertising ID)は、いずれもスマートフォン端末等ごとに発行される広告配信用のオンライン識別子（広告ID）です。IDFAはApple社がiOS端末向け、AAIDはGoogle社がAndroid端末向けにそれぞれ発行しています。<br></br>
              <br></br>
              第4条（データに関するお問合せ等）<br></br>
              当社は、本サービスの利用に関するデータのすべてをバックアップする義務を負うものではありません。データの消失又は改変によって、受診者又は第三者に発生した損害について、当社は一切の責任を負わないものとします。<br></br>
              <br></br>
              第3章　ご留意事項<br></br>
              <br></br>
              第5条　（禁止事項） <br></br>
              受診者は、本サービスの利用に際し、以下に掲げる各号の行為を行ってはならないものとします。違反した場合、当社は当該受診者への事前の通知なく、本サービスの提供を停止し、又は登録を削除する等の措置を講じることができるものとします。これにより当該受診者に発生した不利益又は損害について、当社は一切の責任を負わないものとします。<br></br>
              (1)当社又は第三者の権利、利益、名誉権、プライバシー権、その他法令上又は契約上の権利を侵害し、又は侵害する恐れのある行為 <br></br>
              (2)当社の円滑な業務運営及び本サービスの提供を妨げる、又は妨げる恐れのある行為 <br></br>
              (3)当社のサーバー又はネットワークの機能を破壊したり、妨害したりする行為 <br></br>
              (4)本サービスを、提供の趣旨に照らして本来のサービス提供の趣旨や目的とは異なる目的で利用する行為 <br></br>
              (5)意図的に虚偽又は不正確な情報を登録又は提供する行為（第三者のなりすましを含む。）<br></br>
              (6)第三者の個人情報その他プライバシーに関する情報を、不正に収集、開示又は提供する行為 <br></br>
              (7)不正アクセス行為、第三者のアカウントを利用する行為、複数のアカウントを作成し、又は保有する行為、その他これらに類する行為 <br></br>
              (8)ID及びパスワードを第三者に使用させ、譲渡し、貸与し、又は名義変更する等の行為 <br></br>
              (9)本規約に違反する行為、又は当社が本規約に違反すると判断する行為<br></br>
              (10)その他、当社が不適切と判断する行為<br></br>
              <br></br>
              第6条　（本サービスの変更・停止・終了）<br></br>
              当社は、以下に掲げる各号のいずれかに該当する場合、受診者への事前の通知なく本サービスを変更・停止・終了することができるものとします。これにより受診者に発生した不利益又は損害について、当社は一切の責任を負わないものとします。<br></br>
              (1)本サービスを提供するためのシステム、インフラ等について、定期的な保守、点検、若しくは更新又は何らかの緊急の必要を要する事態が発生した場合<br></br>
              (2)当社の責に帰さない不可抗力又はその他不測の事態により、本サービスの提供をすることが困難である場合<br></br>
              (3)その他当社が必要と判断する場合 <br></br>
              <br></br>
              第7条　（本規約の変更）<br></br>
              当社は、受診者への事前の通知又は受診者の事前の承諾を得ることなく、本規約を変更することができるものとし、本規約が変更された場合、本サービスの利用条件は、変更後の最新の本規約によるものとします。<br></br>
              <br></br>
              第8条　（免責事項）<br></br>
              (1) 当社は、本サービスに事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。当社は、受診者に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。 <br></br>
              (2) 天災地変、防御水準を超えた不正アクセスによるインターネットの通信障害、本サービスの利用不能又はデータの未着及び混乱に関連する事由に起因し、又は関連して受診者に生じた損害について、当社は一切の責任を負わないものとします。<br></br>
              (3) 当社の故意又は重大な過失による場合を除き、受診者による本サービスの利用に関連して受診者に生じた損害について、当社は一切の責任を負わないものとします。 <br></br>
              <br></br>
              第9条　（知的財産の取扱い）<br></br>
              本サービスの提供に際して、使用又は掲載されている情報、内容、商標、ロゴマーク等に関する著作権、商標権、その他の知的財産又は知的財産権は、特に明記されていない限り、当社に帰属します。当社は、受診者が、私的利用その他法律によって明示的に認められる範囲を超えて、これらの一部又は全部を、そのまま又は改変して転用又は複製することを禁止します。 <br></br>
              <br></br>
              第10条　（一般法令の適用）<br></br>
              本規約において定めのない事項については、民法又は商法その他関連する法令の定め（例えば、不法行為及び債務不履行に関する定めなどがありますが、これらに限りません。）が適用されます。 <br></br>
              <br></br>
              第11条　（管轄裁判所）<br></br>
              本サービス及び本規約に関する一切の紛争、請求等は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。 <br></br>
              <br></br>
              第12条　（準拠法）<br></br>
              本規約の効力、適用、解釈にあたっては、日本法が適用されるものとします。<br></br>
              <br></br>
              2023年3月8日制定<br></br>
            </p>
        </div>
      </div>
    </>
  );
};

export default TermsModal
