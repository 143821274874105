import React, { useState, useEffect, useRef } from 'react';
import NumberTestDisplay from '../NumberTestReverseDisplay/NumberTestReverseDisplay';
import NumberTestNumberPad from '../NumberTestReverseNumberPad/NumberTestReverseNumberPad';
import TestInstructions from '../TestInstructions/TestInstructions'
import TestCompleted from '../TestCompleted/TestCompleted'
import { useAuthState } from '../../../../Context';
import blueCircle from '../../../../Images/Circle.svg'
import redCross from '../../../../Images/Cross.svg'
import './number-test-reverse.css'
import ProgressBar from '../../../ProgressBar/ProgressBar';
let testProgressBar = 4


export default function NumberTestReverse() {
  const userDetails = useAuthState();
  const user = userDetails.user;
  const [showNumbers, setShowNumbers] = useState(false);
  const [startButtonShow, setStartButtonShow] = useState(true)
  const [showNumberPad, setShowNumberPad] = useState(false)
  const [showInstructions, setShowInstructions] = useState(true)
  const [testFinished, setTestFinished] = useState(false)
  let [score, setScore] = useState(0)
  let [rounds, setRounds] = useState(1)
  let [wrongCounter, setWrongCounter] = useState(0)
  const [showingCorrectAnswerText, setShowingCorrectAnswerText] = useState(false)
  const [showingIncorrectAnswerText, SetShowingIncorrectAnswerText] = useState(false)
  const [disablingButton, setDisablingButton] = useState(false)
  // const correctSoundEffect = new Audio("/CorrectAnswer.mp3")
  // const incorrectSoundEffect = new Audio("/WrongAnswer3.mp3")
  let amountOfNumbersToDisplay = useRef(2)
  let randomNumberArray = useRef([])
  let reverseCorrectArrayToDisplay = useRef([])


  useEffect(() => {
    randomNumberArray.current = randomizeArray(amountOfNumbersToDisplay.current)
    //console.log("use effect game", randomNumberArray.current)
    return () => {
      // clearInterval(interval);
      // clearTimeout(timeout);
    };
  },[])


  // const playCorrectSound = () => {
  //   correctSoundEffect.play()
  // }


  // const playIncorrectSound = () => {
  //   incorrectSoundEffect.play()
  // }


  const endTest = () => {
    setTestFinished(true)
  }


  const hideInstructions = () => {
    setShowInstructions(false)
  }


const handleStartButton = () => {
  setShowNumbers(true)
  setStartButtonShow(false)
}


const hideNumbersAndDisplayPad = () => {
  setShowNumbers(false)
  setShowNumberPad(true)
}

//The below two functions work together to cause a delay to show the correct/incorrect answers before continuing.
const delay = ms => new Promise(res => setTimeout(res, ms));


const delayExecution = async (isCorrect) => {
  await delay(1500);
  //console.log("Waited 1s");
  removeCirclesCrossesAndText(isCorrect)
};

//This function removes all circles, crosses, and text and continues to the next round.
const removeCirclesCrossesAndText = (isCorrect) => {
  randomNumberArray.current = randomizeArray(amountOfNumbersToDisplay.current);
  setShowingCorrectAnswerText(false)
  SetShowingIncorrectAnswerText(false)
  SetShowingIncorrectAnswerText(false)
  setDisablingButton(false)
  //Only increase rounds number if answer was correct
  if (rounds <= 3 && isCorrect === true) {
    setRounds(rounds = rounds + 1)
  }
  if (wrongCounter === 2 || rounds > 2){
    endTest()
  }
  //Moves onto next round
  setShowNumbers(true)
  setShowNumberPad(false)
}


const displayCorrectIncorrectIcons = (isCorrect) => {
  if (isCorrect === true) {
    setDisablingButton(true)
    setShowingCorrectAnswerText(true)
    delayExecution(isCorrect)
  } else {
    setDisablingButton(true)
    SetShowingIncorrectAnswerText(true)
    //delayExecution(isCorrect)
  }
}


  const randomizeArray = (arrayLength) => {
    //Generates an array of random numbers of length equal to arrayLength
    const array = [...Array(10).keys()]
    const shuffledArray = array.sort((a, b) => 0.5 - Math.random());
    const numberArray = shuffledArray.slice(0, arrayLength)
    return numberArray
  }


  const handleOkButton = (userInputNumber) => {
    //Prevent empty answer or OK misclick
    if (userInputNumber) {
      //The OK button will assert right or wrong answer and will start a new round in these cases:
      //If the number was correctly input, move to the next round and increase digits to display by one.  Also clear the wrong answer counter back to 0.
      //If the number was wrong, repeat current round with current number of digits to display.
      //If the number is wrong again, end the test.
      //console.log("userInputNumber from component", userInputNumber)
      let answerArrayString = userInputNumber.split('')
      let answerArrayInt = []
      //console.log("string answerArray", answerArrayString)
      for (let i = 0; i < answerArrayString.length; i++) {
        answerArrayInt.push(parseInt(answerArrayString[i]))
      }
      //console.log("int array", answerArrayInt)
      //console.log("originalArray", randomNumberArray.current)
      // If correct:
      if (JSON.stringify(answerArrayInt.reverse())===JSON.stringify(randomNumberArray.current)) {
        //console.log("Correct!")
        //playCorrectSound()
        displayCorrectIncorrectIcons(true)
        setScore(score = score + 1)
        amountOfNumbersToDisplay.current++;
        randomNumberArray.current = randomizeArray(amountOfNumbersToDisplay.current);
        setWrongCounter(0)
      } else {
        // If incorrect
        reverseCorrectArrayToDisplay.current = randomNumberArray.current.reverse()
        //console.log("Incorrect!")
        //playIncorrectSound()
        displayCorrectIncorrectIcons(false)
        setWrongCounter(wrongCounter = wrongCounter + 1)
      }
    }
  }


  const headerAndStartButtonDisplay = () => {
    return (
      <div>
        <ProgressBar testProgressBar={testProgressBar}/>
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>
              処理機能テスト（後半）<small>練習問題</small>
            </h1>
          </div>
        </div>

        <div className="score-info ">
          {user.is_admin && <div className="total-score ">Total Score: {score}</div>}
        <div className="total-rounds">第{rounds}問</div>
        </div>
        {user.is_admin && <div className="total-rounds">Digits to Display: {amountOfNumbersToDisplay.current} </div>}
        {user.is_admin && <div className="total-rounds">WrongCounter: {wrongCounter} </div>}
        <div className="wordbuttons__ok-button-container">
          {startButtonShow && <button className="wordbuttons__ok-button" onClick={()=> handleStartButton()}>数字を表示</button>}
          <br/>
          <br/>
          <div className="startText">
          これから表示される順とは逆の順に数字を選んでください。
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
    {testFinished ? <TestCompleted score={score}/> :
      showInstructions ? <TestInstructions hideInstructions={hideInstructions}/> :
      startButtonShow ? headerAndStartButtonDisplay() :
    <>

      <ProgressBar testProgressBar={testProgressBar}/>

      {showNumberPad && showingCorrectAnswerText && (
        <div className="correct-incorrect-answer-display-container">
          <div className="correctMessage">
            <img src={blueCircle} alt="正解" height="40" width="40"/> 正解です
          </div>
        </div>
      )}


      {showNumberPad && showingIncorrectAnswerText && (
        <div>
        <div className="correct-incorrect-answer-display-container">
          <div className="incorrectMessage2">{<img src={redCross} alt="不正解" height="40" width="40"/>} 正解は「{reverseCorrectArrayToDisplay.current.join(' ')}」です。
          <br></br>表示された順とは逆の順に並べてください。
          <button className="show-numbers-btn" onClick={()=> removeCirclesCrossesAndText(false)}>次へ</button>
        </div>
        </div>
        </div>
      )}


      <div className="number-display-and-number-pad-container">
        {showNumbers && (
          <div>
            <div className="correct-incorrect-answer-display-container">
            <div className="score-info ">{user.is_admin && <div className="total-score ">Total Score: {score}</div>}
                <div className="total-rounds">第{rounds}問</div>
              </div>
            </div>
            <NumberTestDisplay randomNumberArray={randomNumberArray.current} hideNumbersAndDisplayPad={hideNumbersAndDisplayPad}/>
            </div>
        )}


        {showNumberPad && (
          <div>
            <div className="correct-incorrect-answer-display-container">
              <div className="score-info ">{user.is_admin && <div className="total-score ">Total Score: {score}</div>}
                <div className="total-rounds">第{rounds}問</div>
              </div>
            </div>
            <NumberTestNumberPad randomNumberArray={randomNumberArray.current} handleOkButton={handleOkButton} disablingButton={disablingButton}/>
          </div>
        )}
      </div>
    </>
  }
  </>
  )
}
