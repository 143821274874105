import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";


export default function BackButtonListener( ) {
  const history = useHistory();
  //const [pressed, setPressed] = useState(false)


  const removeStoredWordArrays = () => {
    localStorage.removeItem('wordArray5')
    localStorage.removeItem('wordArray35')
    localStorage.removeItem('wordArray15')
    localStorage.removeItem('wordArray25')
  }


  useEffect(() => {
        //BACK BUTTON WORKING
        window.onpopstate = () => {
          //setPressed(true)
          window.alert("これでテスト開始画面に戻り、これまでの進行状況は失われます。");
          // window.confirm("You will now be redirected to start page");
          //handleLogout()
          removeStoredWordArrays()
          //setPressed(false)
          history.push('/tests')
          return <Redirect to='/tests' />
        }


      })
      return (
        <>
        {/* <h3>Back button: {pressed.toString()}</h3> */}
        {/* <Prompt when={pressed} message="Are you sure?" /> */}
        </>
      );
    };


  // const pageAccessedByReload = (
  //   (PerformanceNavigationTiming && PerformanceNavigationTiming.TYPE === 1)// ||
      //window.performance
        // .getEntriesByType('navigation')
        // .map((nav) => nav.type)
        // .includes('reload')
  //);
    //
    //if (pageAccessedByReload == true) {
    //if (window.performance.navigation.type === 1) {
    //alert(pageAccessedByReload);
    //   window.alert("これでテスト開始画面に戻り、これまでの進行状況は失われます。");
    //   removeStoredWordArrays()
    //   setPressed(false)
    //   history.push('/tests')
    //   return <Redirect to='/tests' />
    // }

    // window.beforeunload = (e) => {
    //   console.log('Stop this');
    //   //window.alert("これでテスト開始画面に戻り、これまでの進行状況は失われます。");
    //   e.preventDefault()
    //   e.returnValue = '';
    //   history.push('/tests')
    //   return <Redirect to='/tests' />
    // };

    // window.onbeforeunload = () => {
    //   //console.log('Page Refreshed');
    //   window.alert("これでテスト開始画面に戻り、これまでの進行状況は失われます。");
    //   // window.confirm("You will now be redirected to start page");
    //   //handleLogout()
    //   removeStoredWordArrays()
    //   //setPressed(false)
    //   history.push('/tests')
    //   return <Redirect to='/tests' />
    // };


    // if(loc.state == undefined || loc.state == null || loc.state == ''){
    //   history.push('/tests')
    //   return <Redirect to='/tests' />
    // }


    //---
    // const pageAccessedByReload = (
    //   (window.performance.navigation && window.performance.navigation.type === 1) ||
    //     window.performance
    //       .getEntriesByType('navigation')
    //       .map((nav) => nav.type)
    //       .includes('reload')
    // );

    // if (pageAccessedByReload) {
    //   //alert(pageAccessedByReload);
    //   setPressed(true)
    //   window.alert("これでテスト開始画面に戻り、これまでの進行状況は失われます。");
    //   // window.confirm("You will now be redirected to start page");
    //   //handleLogout()
    //   removeStoredWordArrays()
    //   setPressed(false)
    //   history.push('/tests')
    //   return <Redirect to='/tests' />
    // }
    //---
    //if (sessionStorage.getItem("is_reloaded")) alert('Reloaded!');

    // if (PerformanceNavigationTiming.type === "reload") {
    //   window.alert("これでテスト開始画面に戻り、これまでの進行状況は失われます。");
    //   removeStoredWordArrays()
    //   setPressed(false)
    //   history.push('/tests')
    //   return <Redirect to='/tests' />
    //   //window.location.href = "/About"
    // }

