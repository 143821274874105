import React, { useState, useEffect, useRef, createContext } from "react";
import "./test-instructions-part2.css";
//import Cookies from "js-cookie";
import Wordbuttons from "../Wordbuttons/wordbuttons";
import TestCompletedFinal from "../TestCompletedFinal/TestCompletedFinal";
import { useHistory, Redirect } from "react-router-dom";
import { useAuthState,
          //logout,
          //useAuthDispatch,
        } from "../../../Context";
import Explain from "../../../Images/explain@2x.png";

//import Alert from "../../../Images/alert.svg";

import ProgressBar from "../../ProgressBar/ProgressBar";

export const WordContext = createContext();

export default function TestInsturctions() {
  const userDetails = useAuthState();
  //const dispatch = useAuthDispatch();
  const user = userDetails.user;
  const [showInstructions, setShowInstructions] = useState(true);
  const [testFinished, setTestFinished] = useState(false);
  const [startButtonShow, setStartButtonShow] = useState(true);
  const [wordPadShow, setWordPadShow] = useState(false);
  let [score, setScore] = useState(0);
  let [totalPossibleScore, setTotalPossibleScore] = useState(0);
  //let [rounds, setRounds] = useState(0)
  //let rounds = 0;
  let words15Array = useRef([]);
  let words25Array = useRef([]);
  let words40Array = useRef([]);
  let userAnswerArray = [];

  const history = useHistory();
  let testProgressBar = 5;
  let testProgressPage = 0;
  let onPage = 11;

  const saveOnPage = (onPage) => {
    localStorage["onPage"] = JSON.stringify(onPage);
  };

  const checkProperNavigation = () => {
    //Verifies that the user came from Start Button Page, if not then return user to start button page.
    //Start Page = 0, Pract Word = 1, Word Mem = 2, Prac Stroop A = 3, Stroop A = 4, Prac Stroop B = 5, Stroop B = 6, Prac Num = 7, Num Mem = 8, Prac Rev Num = 9, Rev Num = 10, Word Mem part 2 = 11
    testProgressPage = localStorage.getItem("onPage");
    //console.log("user is in page", testProgressPage);
    //console.log("user is admin", user.is_admin)
    //Only Admins can navigate to URLs directly without proper test navigation flow check.
    if (!user.is_admin) {
      if (!testProgressPage || parseInt(testProgressPage) !== 10) {
        console.log("Not proper navigation, returning to start page");
        history.push("/tests");
        return <Redirect to="/tests" />;
      }
    }
    //If actually comes from previous page then update page number.
    saveOnPage(onPage);
  };

  useEffect(() => {
    checkProperNavigation();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   console.log("In testinstructionspart2")
  // },[])

  const shuffleArray = (array) => {
    //Randomize the order of 40 word array
    return array.sort((a, b) => 0.5 - Math.random());
  };

  const retrieveLocalStorage15 = () => {
    let storedArray = JSON.parse(localStorage["wordArray15"]);
    //console.log("storedArray", storedArray)
    return storedArray;
  };

  const retrieveLocalStorage25 = () => {
    let storedArray = JSON.parse(localStorage["wordArray25"]);
    //console.log("storedArray", storedArray)
    return storedArray;
  };


  const hideWordDisplayAndShowWordButtons = () => {
    //setShowWordDisplay(false)
    setWordPadShow(true);
  };

  const hideInstructions = () => {
    setShowInstructions(false);
  };

  const endTest = () => {
    setTestFinished(true);
  };

  const handleOkButton = () => {
    //Calculate correct words in user answer array.
    const correctAnswers = words15Array.current.filter(
      (e) => userAnswerArray.indexOf(e) !== -1
    );
    ////console.log("CorrectAnswers",correctAnswers)
    //Update score
    setScore((score = score + correctAnswers.length));
    setTotalPossibleScore((totalPossibleScore = totalPossibleScore + 15));
    // handleResult(rounds, correctAnswers.length);
    endTest();
  };

  const generate15And25And40RandomWords = () => {
    //This function retreived the 15 correct words from the first Word test from local storage.
    //Then generates 25 unrepeated incorrect words from trimmed down copy of master array
    //Then generates 40 unrepeated array joining correct and incorrect words.
    words15Array.current = [];
    words25Array.current = [];
    words40Array.current = [];

    //Retrieve 15 correct words from Master word array
    const storedWordArray15 = localStorage.getItem("wordArray15");
    if (!storedWordArray15) {
      console.log(
        "ERROR: There is no stored Correct Word Array found in localstorage"
      );
    } else {
      words15Array.current = retrieveLocalStorage15();
    }

    //Generate 25 Incorrect words from remaning array of words
    const storedWordArray25 = localStorage.getItem("wordArray25");
    if (!storedWordArray25) {
      console.log(
        "ERROR: There is no stored Incorrect Word Array found in localstorage"
      );
    } else {
      words25Array.current = retrieveLocalStorage25();
    }

    words40Array.current = words15Array.current.concat(words25Array.current);
    //console.log("copy of Master", copyOfMasterArray)
    //console.log("words40Array", words40Array)
  };

  const handleStartButton = () => {
    hideWordDisplayAndShowWordButtons();
    generate15And25And40RandomWords();
    // console.log("15correctWords", words15Array.current)
    // console.log("nonshuffled", words40Array.current)
    shuffleArray(words40Array.current);
    // console.log("wordsShuffled40",words40Array.current)
    //increaseRoundsCounter()

    setStartButtonShow(false);
    //setShowWordDisplay(true)
    hideInstructions();
    setWordPadShow(true);
  };

  const handleUserWordsInput = (word) => {
    if (userAnswerArray.includes(word)) {
      const index = userAnswerArray.indexOf(word);
      if (index > -1) {
        userAnswerArray.splice(index, 1);
      }
    } else {
      userAnswerArray.push(word);
    }
    //console.log("userAnswerArray",userAnswerArray)
  };

  const wordContextValue = {
    handleUserWordsInput,
  };

  return (
    <>
      {testFinished ? (
        <TestCompletedFinal score={score} />
      ) : showInstructions ? (
        <>
          <ProgressBar testProgressBar={testProgressBar} />

          <div className="head welcome-text-button-results-container">
            <div className="welcome-text-container">
              <h1>記憶力テスト（後半）</h1>
            </div>
          </div>

          <div className="text-box">
          <div className="flex">
            <div>
              <img src={Explain} alt="説明" />
            </div>
          </div>
          <p className="instructions-text">
          テストの最初に覚えた<u><b>15個</b></u>の単語を思い出して、次のリストから選んでください。
          </p>
          </div>


          <div className="start-button-container">
            {startButtonShow && (
              <button
                className="show-words-btn"
                onClick={() => handleStartButton()}
              >
                単語を表示
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          <ProgressBar testProgressBar={testProgressBar} />
          {/* <div className="head welcome-text-button-results-container">
            <div className="welcome-text-container">
              <h1>記憶力テスト（後半）</h1>
            </div>
          </div> */}

          <WordContext.Provider value={wordContextValue}>
            {/* {showWordDisplay && <WordDisplay words15Array={words15Array.current} hideWordDisplayAndShowWordButtons={hideWordDisplayAndShowWordButtons}/>} */}
            {wordPadShow && (
              <Wordbuttons
                words40={words40Array.current}
                handleOkButton={handleOkButton}
                userAnswerArray={userAnswerArray}
                handleUserWordsInput={handleUserWordsInput}
              />
            )}
          </WordContext.Provider>
        </>
      )}
    </>
  );
}
