import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import './test-completed-final.css'
import { Link } from "react-router-dom";
import { useAuthState, logout, useAuthDispatch } from '../../../Context';
import Otsukare from "../../../Images/otsukare@2x.png";

//import ProgressBar from '../../ProgressBar/ProgressBar';
//let testProgressBar = 5

export default function TestCompleted({score}) {

  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const user = userDetails.user;

  useEffect(() => {
    localStorage.removeItem('wordArray15')
    localStorage.removeItem('wordArray25')
  }, [])


  useEffect(() => {
    let csrftoken = Cookies.get('csrf_refresh_token');
    const requestOptions = {
		method: 'POST',
		headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "X-CSRFToken": csrftoken
        },
    credentials: 'include',
    body: JSON.stringify({
        round:2,
        score:score
    }),
    };
    fetch('/api/test/word_memory', requestOptions)
    .then(response => response.json())
    // .then(fetch('/api/visit', {
    //   method: 'PUT',
    //   body: JSON.stringify({
    //       visitId:user.lastVisit,
    //   }),
    //   headers: {
    //       "Content-Type": "application/json; charset=UTF-8"
    //   }
    //   }).then(response => response.json())
    //   .catch(err=>{
    //     console.log("err", err);
    //     logout(dispatch);
    //     }))
    .catch(err=>{
      console.log("err", err);
      logout(dispatch);
      })
  // eslint-disable-next-line
  }, []);


  const crossMarketRedirect = () => {
    window.location.href = `https://rsch.jp/common/connect_end.php?id=${user.id}&eqtid=${user.eqtid}&state=8lUjzMGEMY`
    logout(dispatch)
  };

  return (
    <>

      {/* <ProgressBar testProgressBar={testProgressBar}/> */}
      <div className="color-test-completed">
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>記憶力テスト</h1>
          </div>
        </div>



        <div className="text-box">
          <div className="flex">
            <div className="exam-icon">
              <img src={Otsukare} alt="おつかれさまでした" />
            </div>
            <div className="instructions-text">
              <h2 className="instructions-text-center">おつかれさまでした。</h2>
              <div className="instructions-text">{user.is_admin && (<div className="instructions-text-center">Your Total Score is: {score}</div>)}</div>
              <p className="instructions-text-center">
                <b>
                すべてのテストが終了しました。
                </b>
              </p>
            </div>
          </div>
        </div>

        {
        user.id && user.eqtid ?
          <button className="continue-button" onClick={() => crossMarketRedirect()}><h2>クロス・マーケティングへ</h2></button>
          :
          <Link to="/results" className="continue-button"><h2>結果を表示する</h2></Link>
        }
        {/* <button className="continue-button" >Continue</button> */}
      </div>
    </>
  )
}
