import React, { useEffect, useRef, useState} from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { useAuthDispatch, newVisit, logout } from "../Context";
import './new-visit.css';
import { isBrowser, isMobile, browserName, browserVersion, osName, isDesktop, isTablet, deviceType} from 'react-device-detect';

export const NewVisit = ({ testing_allowed, next_available_date }) => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  let nextAvailableDate = useRef([]);
  let subdomain = useRef();
  const [currentHospital, setCurrentHospital] = useState('');  //currentHospital is the subdomain that we need to save in the Database upon creating a new visit.
  let onPage = 0;
  console.log("next dateeeeeee",next_available_date)

  const createNewVisit = () => {
    newVisit(dispatch)
    .then(response => {
      if (response.user) {
        let userDeviceSpecs = {};
        let networkData = {}

        userDeviceSpecs.isBrowser = isBrowser
        userDeviceSpecs.isMobile = isMobile
        userDeviceSpecs.browserName = browserName
        userDeviceSpecs.browserVersion = browserVersion
        userDeviceSpecs.osName = osName
        userDeviceSpecs.isDesktop = isDesktop
        userDeviceSpecs.isTablet = isTablet
        userDeviceSpecs.deviceType = deviceType

        for (let key in navigator.connection) {
          if (typeof navigator.connection[key] !== "function") {
            networkData[key] = navigator.connection[key];
          }
        }
        saveUserDeviceSpecs(userDeviceSpecs, networkData);
        saveHospitalReference();
        history.push('/practice/stroop-test-b');
      }
    })
    .catch(error => {
      history.push('/tests');
    });
  };


  const saveOnPage = (onPage) => {
    localStorage["onPage"] = JSON.stringify(onPage);
  }


  useEffect(() => {
    localStorage.removeItem('wordArray15')
    localStorage.removeItem('wordArray25')

    nextAvailableDate = next_available_date
    //Remove onPage array thay may exist and replace it with a new one with value at 0 representing the Start button page.
    localStorage.removeItem('onPage')
    saveOnPage(onPage)
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.')// parts[0] should be the subdomain if there is any
    if(parts.length > 2) {
      const subdomain = parts[0]
      setCurrentHospital(subdomain)
      console.log("Subdomain: ", subdomain)
    } else {
      console.log("No subdomain in use")
    }
}, [])


  const saveUserDeviceSpecs = (userDeviceSpecs, networkData) => {
    let csrftoken = Cookies.get("csrf_refresh_token");
    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRFToken": csrftoken,
      },
      credentials: "include",
      body: JSON.stringify({
        accessDeviceType: userDeviceSpecs,
        networkInformation: networkData
      }),
    };
      fetch('/api/device_specs', requestOptions)
        .then((response) => response.json())
        .catch((err) => {
          console.log("err", err);
          logout(dispatch);
        });
  }


  const saveHospitalReference = () => {
    let csrftoken = Cookies.get("csrf_refresh_token");
    const requestOptions = {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRFToken": csrftoken,
      },
      credentials: "include",
      body: JSON.stringify({
        // hospitalRef: subdomain.current,
        hospitalRef: currentHospital,
      }),
    };
      fetch('/api/hospital_ref', requestOptions)
        .then((response) => response.json())
        .catch((err) => {
          console.log("err", err);
        });
  }

  return (
  testing_allowed ? <button className="test-navigation-btn large-text" onClick={createNewVisit}>テスト開始</button> : <button className="test-navigation-btn-disabled large-text" style = {{color : "white", background : "darkgray", fontSize : "28px" }} disabled>次のテストまでお待ちください<br></br><span style={{ fontSize : "17px", fontWeight: "bold" }}>※テストは前回受検日から一週間が経過すると<br></br>{ next_available_date }再度受検可能となります。</span></button>
  )
};
