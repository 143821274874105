import React from 'react';
//import Logo from '../../Images/logo.png';
import '../Header/common.css'
import '../Header/header.css'
import { Link } from "react-router-dom";
import {
          //useAuthDispatch,
          //logout,
          useAuthState } from '../../Context';
//import { useHistory } from "react-router-dom";


export default function Header(props) {
  //const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
  const user = userDetails.user;
  //const history = useHistory();

  // const handleLogout = () => {
	// 	logout(dispatch);
	// 	history.push('/login');
	// };

  return (
      user ?
      <div>
        <span>
          <span>
            {user.is_admin && <div className="admin-link"><Link to='/admin'>Admin</Link></div>}
          </span>
            {user.is_admin && <div><Link to='/results'>Results</Link></div>}
        </span>
      </div>
    :
      <div>

      </div>
    )
}
