import React from 'react'
import './adminpage.css'
import { Link, Redirect } from "react-router-dom";
import { useAuthState } from '../../Context';


export default function MainTestPage() {
	const userDetails = useAuthState();
  const user = userDetails.user;

  return (
    !user.is_admin?
      <Redirect to='/404' />
    :
    <>
      <div>
        <Link to="/practice/word-memory">
          <button className="test-navigation-btn large-text">
            PRAC Word Memory Test
          </button>
        </Link>
      </div>
      <div>
        <Link to="/test/word-memory">
          <button className="test-navigation-btn large-text">
            Word Memory Test
          </button>
        </Link>
      </div>
      <div>
        <Link to="/test/word-memory-2">
          <button className="test-navigation-btn large-text">
            Word Memory Pt 2
          </button>
        </Link>
      </div>
      <div>
        <Link to="/practice/stroop-test-a">
          <button className="test-navigation-btn large-text">
            PRAC Stroop Test Part A
          </button>
        </Link>
      </div>
      <div>
        <Link to="/test/stroop-test-a">
          <button className="test-navigation-btn large-text">
            Stroop Test Part A
          </button>
        </Link>
      </div>
      <div>
        <Link to="/practice/stroop-test-b">
          <button className="test-navigation-btn large-text">
            PRAC Stroop Test Part B
          </button>
        </Link>
      </div>
      <div>
        <Link to="/test/stroop-test-b">
          <button className="test-navigation-btn large-text">
            Stroop Test Part B
          </button>
        </Link>
      </div>
      <div>
        <Link to="/practice/number-memory">
          <button className="test-navigation-btn large-text">
            PRAC Num Memory Test
          </button>
        </Link>
      </div>
      <div>
        <Link to="/test/number-memory">
          <button className="test-navigation-btn large-text">
            Number Memory Test
          </button>
        </Link>
      </div>
      <div>
        <Link to="/practice/number-memory-reverse">
          <button className="test-navigation-btn small-text">
            PRAC Reverse Num Memory
          </button>
        </Link>
      </div>
      <div>
        <Link to="/test/number-memory-reverse">
          <button className="test-navigation-btn small-text">
            Reverse Number Memory Test
          </button>
        </Link>
      </div>
    </>
  )
}
