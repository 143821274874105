import { useCallback, useEffect, useState } from "react";
import './worddisplay.css'

let timeout;
let interval;

export default function WordDisplay({words15Array, hideWordDisplayAndShowWordButtons}) {
  //console.log("wordsdisplayArray", words15Array)
  const [n, setN] = useState(0);
  const [hidden, setHidden] = useState(false);


  const rotateWords = useCallback(() => {
    setN((prevN) => {
      let nextN = prevN + 1;

      if (nextN >= words15Array.length) {
        nextN = 0;
        //randomNumberArray = []
        // clearInterval(interval);
        // clearTimeout(timeout);
        hideWordDisplayAndShowWordButtons()
      }
      return nextN;
    });
    setTimeout(() => {
      setHidden(true);
    }, 1500);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    interval = setInterval(() => {
      setHidden(false);
      rotateWords();
      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }, 2000);

    setTimeout(() => {
      setHidden(true);
    }, 1500);

    return () => {

    }

    // eslint-disable-next-line
  }, [rotateWords]);



  return (
    <>
      <div className="worddisplay__random-word-display">{!hidden && words15Array[n]}</div>
    </>
  )
}
