import React, {useState, useRef, useEffect} from 'react';
import './stroop-parta-test.css'
import TestInstructions from '../TestInstructions/TestInstructions'
import TestCompleted from '../TestCompleted/TestCompleted'
import { useAuthState } from '../../../../Context';
import blueCircle from '../../../../Images/Circle.svg'
import redCross from '../../../../Images/Cross.svg'
import ProgressBar from '../../../ProgressBar/ProgressBar';
let testProgressBar = 1

export default function StoopPartATest() {
  const userDetails = useAuthState();
  const user = userDetails.user;

  let [circleColor, setCircleColor] = useState({ background: 'red' })
  let [correctScore, setCorrectScore] = useState(0)
  let [incorrectScore, setIncorrectScore] = useState(0)
  let [currentRound, setCurrentRound] = useState(1)
  const [showInstructions, setShowInstructions] = useState(true)
  const [testFinished, setTestFinished] = useState(false)
  const [timer, setTimer] = useState(15);
  const [circleOnBlueButton, setCircleOnBlueButton] = useState(false)
  const [crossOnBlueButton, setCrossOnBlueButton] = useState(false)
  const [circleOnYellowButton, setCircleOnYellowButton] = useState(false)
  const [crossOnYellowButton, setCrossOnYellowButton] = useState(false)
  const [circleOnBlackButton, setCircleOnBlackButton] = useState(false)
  const [crossOnBlackButton, setCrossOnBlackButton] = useState(false)
  const [circleOnGreenButton, setCircleOnGreenButton] = useState(false)
  const [crossOnGreenButton, setCrossOnGreenButton] = useState(false)
  const [circleOnRedButton, setCircleOnRedButton] = useState(false)
  const [crossOnRedButton, setCrossOnRedButton] = useState(false)
  const [blueColorOnBlueButton, setBlueColorOnBlueButton] = useState(false)
  const [blueColorOnYellowButton, setBlueColorOnYellowButton] = useState(false)
  const [blueColorOnBlackButton, setBlueColorOnBlackButton] = useState(false)
  const [blueColorOnGreenButton, setBlueColorOnGreenButton] = useState(false)
  const [blueColorOnRedButton, setBlueColorOnRedButton] = useState(false)
  const [showingCorrectAnswerText, setShowingCorrectAnswerText] = useState(false)
  const [showingIncorrectAnswerText, SetShowingIncorrectAnswerText] = useState(false)
  const [disablingButton, setDisablingButton] = useState(false)
  // const correctSoundEffect = new Audio("/CorrectAnswer.mp3")
  // const incorrectSoundEffect = new Audio("/WrongAnswer3.mp3")
  let colorRandom = useRef()
  let currentCorrectAnswer = useRef()
  const colors = ['blue', 'yellow', 'black', 'green', 'red']


  useEffect(() => {
    startRound()
    // return () => {
    //   cleanup
    // }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (timer === 0 && showInstructions === false) {  //Prevents time running out and test ending while Instructions are displayed.
        endTest()
    }
    if (timer > 0 && testFinished === false){  // Stops countdown on the Otsukaresama page.
      const countdown = () => setTimeout(() => setTimer(timer - 1) , 1000);
      const timerId = countdown();
      return () => {
        clearTimeout(timerId);
      };
    }
    }
    // eslint-disable-next-line
  , [timer])


  const randomizeColor = (colorsArray) => {
    //Choose a random color from colors array
    const randomColor = colorsArray[Math.floor(Math.random() * colorsArray.length )];
    return randomColor
  }


  const applyColorToCircle = () => {
    const no_prev_color = colors.filter(e => e !== circleColor["background"])
    colorRandom.current = randomizeColor(no_prev_color)
    setCircleColor({ background: colorRandom.current })
    translateColorWord(colorRandom.current)
  }


  // const playCorrectSound = () => {
  //   correctSoundEffect.play()
  // }


  // const playIncorrectSound = () => {
  //   incorrectSoundEffect.play()
  // }


  const hideInstructions = () => {
    setShowInstructions(false)
    setTimer(15)
  }


  const endTest = () => {
    setTestFinished(true)
    //console.log("Part A TEST if finished")
  }


  //The below two functions work together to cause a delay to show the correct/incorrect answers before continuing.
  const delay = ms => new Promise(res => setTimeout(res, ms));


  const delayExecution = async (delayLength) => {
    await delay(delayLength);
    //console.log("Waited 1s");
    removeCirclesCrossesAndText()
  };

  //This function displays the blue circle when the user selects the correct answer
  const displayCorrectIcons = (actualAnswer) => {
    setDisablingButton(true)
    if (actualAnswer === 'blue') {
      setCircleOnBlueButton(true)
      setShowingCorrectAnswerText(true)
      delayExecution(800)
    }
    if (actualAnswer === 'yellow') {
      setCircleOnYellowButton(true)
      setShowingCorrectAnswerText(true)
      delayExecution(800)
    }
    if (actualAnswer === 'black') {
      setCircleOnBlackButton(true)
      setShowingCorrectAnswerText(true)
      delayExecution(800)
    }
    if (actualAnswer === 'green') {
      setCircleOnGreenButton(true)
      setShowingCorrectAnswerText(true)
      delayExecution(800)
    }
    if (actualAnswer === 'red') {
      setCircleOnRedButton(true)
      setShowingCorrectAnswerText(true)
      delayExecution(800)
    }
  }

  //This function displays a circle on the correct answer and a cross on the incorrect (selected) answer
  const displayCorrectIncorrectIcons = (actualAnswer, selectedAnswer) => {
    setDisablingButton(true)
    if (selectedAnswer === 'blue') {
      setCrossOnBlueButton(true)
    }
    if (selectedAnswer === 'yellow') {
      setCrossOnYellowButton(true)
    }
    if (selectedAnswer === 'black') {
      setCrossOnBlackButton(true)
    }
    if (selectedAnswer === 'green') {
      setCrossOnGreenButton(true)
    }
    if (selectedAnswer === 'red') {
      setCrossOnRedButton(true)
    }
    if (actualAnswer === 'blue') {
      setBlueColorOnBlueButton(true)
      SetShowingIncorrectAnswerText(true)
      delayExecution(2000)
    }
    if (actualAnswer === 'yellow') {
      setBlueColorOnYellowButton(true)
      SetShowingIncorrectAnswerText(true)
      delayExecution(2000)
    }
    if (actualAnswer === 'black') {
      setBlueColorOnBlackButton(true)
      SetShowingIncorrectAnswerText(true)
      delayExecution(2000)
    }
    if (actualAnswer === 'green') {
      setBlueColorOnGreenButton(true)
      SetShowingIncorrectAnswerText(true)
      delayExecution(2000)
    }
    if (actualAnswer === 'red') {
      setBlueColorOnRedButton(true)
      SetShowingIncorrectAnswerText(true)
      delayExecution(2000)
    }
  }

  //This function removes all circles, crosses, and text and continues to the next round.
  const removeCirclesCrossesAndText = () => {
    setCircleOnBlueButton(false)
    setCircleOnYellowButton(false)
    setCircleOnGreenButton(false)
    setCircleOnBlackButton(false)
    setCircleOnRedButton(false)
    setCrossOnBlueButton(false)
    setCrossOnYellowButton(false)
    setCrossOnGreenButton(false)
    setCrossOnBlackButton(false)
    setCrossOnRedButton(false)
    setBlueColorOnBlueButton(false)
    setBlueColorOnYellowButton(false)
    setBlueColorOnBlackButton(false)
    setBlueColorOnGreenButton(false)
    setBlueColorOnRedButton(false)
    setShowingCorrectAnswerText(false)
    SetShowingIncorrectAnswerText(false)
    startRound()
    setDisablingButton(false)
  }


  const handleAnswerClick = (selectedAnswer) => {
    //console.log("SelectedAnswer", selectedAnswer)
    //console.log("colorRandom.current in handle", colorRandom.current)
    if (colorRandom.current === selectedAnswer) {
      setCorrectScore(correctScore = correctScore + 1)
      //playCorrectSound()
      displayCorrectIcons(selectedAnswer)
    } else {
      setIncorrectScore(incorrectScore = incorrectScore + 1)
      //playIncorrectSound()
      displayCorrectIncorrectIcons(colorRandom.current, selectedAnswer)
    }
    setCurrentRound(currentRound = currentRound + 1)
    if (currentRound > 100) {
      endTest()
    }
  }


  const startRound = () => {
    applyColorToCircle()
  }


  const translateColorWord = (colorRandom) => {
    //console.log("hehe", colorRandom)
    if (colorRandom === 'black') {
      currentCorrectAnswer.current = 'くろ'
    }
    if (colorRandom === 'red') {
      currentCorrectAnswer.current = 'あか'
    }
    if (colorRandom === 'green') {
      currentCorrectAnswer.current = 'みどり'
    }
    if (colorRandom === 'yellow') {
      currentCorrectAnswer.current = 'きいろ'
    }
    if (colorRandom === 'blue') {
      currentCorrectAnswer.current = 'あお'
    }
  }


  return (
    <>
    {testFinished ? <TestCompleted correctScore={correctScore} incorrectScore={incorrectScore} currentRound={currentRound} /> :
      showInstructions ? <TestInstructions hideInstructions={hideInstructions}/> :
    <>
      <ProgressBar testProgressBar={testProgressBar}/>

      {/* <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>注意力テスト（前半）<small>練習問題</small></h1>
          </div>
        </div> */}


      <div className = "score-info ">
        <span>
          {user.is_admin && <div className = "total-score ">Score: <span style={{ color: 'blue' }}>{ correctScore } </span>| <span style={{ color: 'red' }}>{ incorrectScore } </span></div>}
          {user.is_admin && <div className = "total-games">ラウンド: { currentRound } / 100</div>}
        <div className = "total-games">残り時間: { timer }</div>
        </span>
      </div>
      {/* <div className="hint-container">
        「ヒント」正解は<b>「{currentCorrectAnswer.current}」</b>です。<br></br>
        ※注意点：実際のテストでこのヒントボックスが表示されず、サウンドも再生されません。
      </div> */}
      <div>
        <div className="dot" style={ circleColor }></div>
      </div>

      <div className="buttonsContainer">
        <div className="colorButtonContainer">
          {circleOnBlueButton && <img className="circleOnButton" src={blueCircle} alt="Circle" height="90" width="90"/>}
          {crossOnBlueButton && <img className="crossOnButton" src={redCross} alt="Cross" height="90" width="90"/>}
          {!blueColorOnBlueButton && <button disabled={disablingButton} className='answerButton' onClick={() => handleAnswerClick('blue')}>あお</button>}
          {blueColorOnBlueButton && <button disabled={disablingButton} style = {{background:'#6CA3E3', color:'white'}} className='answerButton' onClick={() => handleAnswerClick('blue')}>あお</button>}
        </div>

        <div className="colorButtonContainer">
        {circleOnYellowButton && <img className="circleOnButton" src={blueCircle} alt="Circle" height="90" width="90"/>}
        {crossOnYellowButton && <img className="crossOnButton" src={redCross} alt="Cross" height="90" width="90"/>}
        {!blueColorOnYellowButton && <button disabled={disablingButton} className='answerButton' onClick={() => handleAnswerClick('yellow')}>きいろ</button>}
        {blueColorOnYellowButton && <button disabled={disablingButton} style = {{background:'#6CA3E3', color:'white'}} className='answerButton' onClick={() => handleAnswerClick('yellow')}>きいろ</button>}

          {/* <button disabled={disablingButton} className='answerButton' onClick={() => handleAnswerClick('yellow')}>きいろ</button> */}
        </div>

        <div className="colorButtonContainer">
          {circleOnBlackButton && <img className="circleOnButton" src={blueCircle} alt="Circle" height="90" width="90"/>}
          {crossOnBlackButton && <img className="crossOnButton" src={redCross} alt="Cross" height="90" width="90"/>}
          {!blueColorOnBlackButton && <button disabled={disablingButton} className='answerButton' onClick={() => handleAnswerClick('black')}>くろ</button>}
          {blueColorOnBlackButton && <button disabled={disablingButton} style = {{background:'#6CA3E3', color:'white'}} className='answerButton' onClick={() => handleAnswerClick('black')}>くろ</button>}

          {/* <button disabled={disablingButton} className='answerButton' onClick={() => handleAnswerClick('black')}>くろ</button> */}
        </div>

        <div className="colorButtonContainer">
          {circleOnGreenButton && <img className="circleOnButton" src={blueCircle} alt="Circle" height="90" width="90"/>}
          {crossOnGreenButton && <img className="crossOnButton" src={redCross} alt="Cross" height="90" width="90"/>}
          {!blueColorOnGreenButton && <button disabled={disablingButton} className='answerButton' onClick={() => handleAnswerClick('green')}>みどり</button>}
          {blueColorOnGreenButton && <button disabled={disablingButton} style = {{background:'#6CA3E3', color:'white'}} className='answerButton' onClick={() => handleAnswerClick('green')}>みどり</button>}
        </div>

        <div className="colorButtonContainer">
          {circleOnRedButton && <img className="circleOnButton" src={blueCircle} alt="Circle" height="90" width="90"/>}
          {crossOnRedButton && <img className="crossOnButton" src={redCross} alt="Cross" height="90" width="90"/>}
          {!blueColorOnRedButton && <button disabled={disablingButton} className='answerButton' onClick={() => handleAnswerClick('red')}>あか</button>}
          {blueColorOnRedButton && <button disabled={disablingButton} style = {{background:'#6CA3E3', color:'white'}} className='answerButton' onClick={() => handleAnswerClick('red')}>あか</button>}
        </div>
      </div>
      {showingCorrectAnswerText && <div className="correctMessage">正解です</div>}
      {showingIncorrectAnswerText && <div className="incorrectMessage">正解は「{currentCorrectAnswer.current}」です</div>}
    </>
    }
  </>
  )
}
