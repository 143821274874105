import React from 'react';
import './App.css';
import AppRoute from './Components/AppRoute/AppRoute';
import routes from './Components/AppRoute/routes.js';
import Header from './Components/Header/header';
import BackButtonListener from './Components/BackButtonListener/BackButtonListener';
import { AuthProvider } from './Context';
import { BrowserRouter, Switch } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Header />
          <BackButtonListener />
          <Switch>
            {routes.map((route) => (
                <AppRoute
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  isPrivate={route.isPrivate}
                />
              ))}
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
