import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../../Context";
import "./test-instructions.css";
import ProgressBar from "../../ProgressBar/ProgressBar";
import Explain from "../../../Images/explain@2x.png";

export default function TestInsturctions({ hideInstructions }) {
  const userDetails = useAuthState();
  const user = userDetails.user;
  const history = useHistory();
  let testProgressBar = 4;
  let testProgressPage = 0;
  let onPage = 10;

  const saveOnPage = (onPage) => {
    localStorage["onPage"] = JSON.stringify(onPage);
  };

  const checkProperNavigation = () => {
    //Verifies that the user came from Start Button Page, if not then return user to start button page.
    //Start Page = 0, Pract Word = 1, Word Mem = 2, Prac Stroop A = 3, Stroop A = 4, Prac Stroop B = 5, Stroop B = 6, Prac Num = 7, Num Mem = 8, Prac Rev Num = 9, Rev Num = 10, Word Mem part 2 = 11
    testProgressPage = localStorage.getItem("onPage");
    //console.log("user is in page", testProgressPage);
    //console.log("user is admin", user.is_admin)
    //Only Admins can navigate to URLs directly without proper test navigation flow check.
    if (!user.is_admin) {
      if (!testProgressPage || parseInt(testProgressPage) !== 9) {
        console.log("Not proper navigation, returning to start page");
        history.push("/tests");
        return <Redirect to="/tests" />;
      }
    }
    //If actually comes from previous page then update page number.
    saveOnPage(onPage);
  };

  useEffect(() => {
    checkProperNavigation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ProgressBar testProgressBar={testProgressBar} />
      <div className="test-instructions">
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>処理機能テスト（後半）</h1>
          </div>
        </div>

        <div className="text-box">
          <div className="flex">
            <div>
              <img src={Explain} alt="説明" />
            </div>
            <p className="instructions-text">
            これから表示される数字を覚えて、<u><b>表示された順とは逆の順に</b></u>数字を選んでください。
            </p>
          </div>
        </div>

        <button className="start-button" onClick={() => hideInstructions()}>
          スタート
        </button>
      </div>
    </>
  );
}
