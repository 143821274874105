import React from 'react'
import './main-test-page.css'
import ResultsHistory from '../ResultsHistory/ResultsHistory';


export default function MainTestPage() {
  return (
  <>
      <ResultsHistory />
  </>
  )
}
