import React, { useState } from "react";
import Cookies from "js-cookie";
//import { logout, useAuthDispatch } from "../../Context";
import { useHistory } from "react-router-dom";
import HeaderLogo from '../HeaderLogo/HeaderLogo'

export default function PersonalInfo() {
  //const dispatch = useAuthDispatch();
  const [gender, setGender] = useState(false);
  //eslint-disable-next-line
  const [birthDate, setBirthDate] = useState(false);
  const [birthYear, setBirthYear] = useState(false);
  const [birthMonth, setBirthMonth] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory();

  // Prefill years and months
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 80)).fill('').map((v, idx) => now - idx);
  const months = Array(12).fill('').map((v, idx) => idx + 1);

  const handleYearChange = (e) => {
    setBirthYear(e.target.value)
  }


  const handleMonthChange = (e) => {
    setBirthMonth(e.target.value)
  }


  const RadioButton = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="radio" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };


  const handleMaleChange = () => {
    setGender('male');
  };


  const handleFemaleChange = () => {
    setGender('female');
  };


  const delay = ms => new Promise(res => setTimeout(res, ms));


  const disappearErrorMessageCounter = async () => {
    await delay(3000);
    setErrorMessage('')
  };


  const handleFormSubmit = (event) => {
    event.preventDefault();
    let csrftoken = Cookies.get("csrf_refresh_token");

    fetch('/api/user/info', {
    method: 'PUT',
    body: JSON.stringify({
        gender:gender,
        birthYear:birthYear,
        birthMonth:birthMonth,
    }),
    credentials: "include",
    headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRFToken": csrftoken,
    }
    }).then(response => response.json())
    .then(data => {
      if (data.user) {
        history.push('/tests');
      } else {
        setErrorMessage(data.error)
        disappearErrorMessageCounter()
        //logout(dispatch);
      }
      //setGender(false)
      setBirthDate(false)
    })
    .catch(err=>{
      console.log("err", err);
      })
  }


  return (
  <>
    <HeaderLogo />
    <div className="signup-box-container">
      <form className="signup-box" onSubmit={handleFormSubmit}>
          <h3 className="login_color">年齢と性別情報</h3>
          <div >
            <label className="signup-gender-label-container">性別</label>
            <div className="gender-radio-buttons-container">
            <RadioButton
              label="男性"
              value={gender === 'male'}
              onChange={handleMaleChange}
            />　
            <RadioButton
              label="女性"
              value={gender === 'female'}
              onChange={handleFemaleChange}
            />
            </div>
          </div>

          <div>
            <label className="signup-dob-label-container">生年月日</label>
            <div className="signup-dob-label-container">
              <select onChange={handleYearChange}>
                <option value="">年</option>
                {years.map((year) => <option value={year}>{year}</option>)}
              </select> 年　
              <select onChange={handleMonthChange}>
                <option value="">月</option>
                {months.map((month) => <option value={month}>{month}</option>)}
              </select> 月
            </div>
          </div>

          <div>
            <br/>
            <span style={{
              fontWeight: 'light',
              color: 'maroon',
              }}>{errorMessage}
            </span>
          </div>
          {!errorMessage && birthYear && birthMonth && gender ?
            <button className="login-signup-button" type="submit">保存</button>
          :
            <button className="login-signup-button-disabled" type="submit" disabled>保存</button>
          }
        </form>
      </div>
  </>);
};
