import React, { useState, useEffect, useRef } from 'react';
import WordDisplay from '../WordDisplay/worddisplay'
import Wordbuttons from '../Wordbuttons/wordbuttons';
import TestInstructions from '../TestInstructions/TestInstructions';
import TestCompleted from '../TestCompleted/TestCompleted';
import './wordmemorytest.css';
import { wordsList } from '../../../../wordsList'
import { useAuthState } from '../../../../Context';
import ProgressBar from '../../../ProgressBar/ProgressBar';
let testProgressBar = 0

export const WordContext = React.createContext()

export default function WordMemoryTest() {
  const userDetails = useAuthState();
  const user = userDetails.user;

  const [startButtonShow, setStartButtonShow] = useState(true)
  const [wordPadShow, setWordPadShow] = useState(false)
  const [showWordDisplay, setShowWordDisplay] = useState(false)
  const [showInstructions, setShowInstructions] = useState(true)
  const [testFinished, setTestFinished] = useState(false)
  let score = 0
  let totalPossibleScore = 0
  //let [score, setScore] = useState(0)
  //let [totalPossibleScore, setTotalPossibleScore] = useState(0)
  let [rounds, setRounds] = useState(0)
  const [hasPressedOk, setHasPressedOk] = useState(false)
  let words5Array = useRef([])
  let words35Array = useRef([])
  let words40Array = useRef([])
  let userAnswerArray = []

  useEffect(() => {
    generate5And35And40RandomWords()
    // eslint-disable-next-line
  },[])

  const saveLocalStorage5 = (wordArray) => {
    localStorage["wordArray5"] = JSON.stringify(wordArray);
  }

  const saveLocalStorage35 = (wordArray) => {
    localStorage["wordArray35"] = JSON.stringify(wordArray);
  }

  const retrieveLocalStorage5 = () => {
    let storedArray = JSON.parse(localStorage["wordArray5"]);
    //console.log("storedArray", storedArray)
    return storedArray
  }

  const retrieveLocalStorage35 = () => {
    let storedArray = JSON.parse(localStorage["wordArray35"]);
    //console.log("storedArray", storedArray)
    return storedArray
  }

  const endTest = () => {
    setTestFinished(true)
  }

  const hideInstructions = () => {
    setShowInstructions(false)
  }

  const handleUserWordsInput = (word) => {
    if (userAnswerArray.includes(word)) {
      const index = userAnswerArray.indexOf(word);
      if (index > -1) {
        userAnswerArray.splice(index, 1);
      }
    } else {
      userAnswerArray.push(word)
    }
    //console.log("userAnswerArray",userAnswerArray)
  }


//   //The below two functions work together to cause a delay to show the correct/incorrect answers before continuing.
//   const delay = ms => new Promise(res => setTimeout(res, ms));


//   const delayExecution = async () => {
//     await delay(3000);
//     console.log("Waited 1s");
//     removeCirclesCrossesAndText()
//   };


// //This function removes all circles, crosses, and text and continues to the next round.
// const removeCirclesCrossesAndText = () => {
//   //if(rounds >= 1) {
//   endTest()
// }


  const handleOkButton = () => {
    //console.log("setHasPressedOk befiore", hasPressedOk)
    setHasPressedOk(true)
    //console.log("setHasPressedOk after", hasPressedOk)
    endTest()
  }


  const increaseRoundsCounter = () => {
    setRounds(rounds = rounds + 1)
  }


  const handleStartButton = () => {
    generate5And35And40RandomWords()
    //console.log("5correctWords", words5Array.current)
    //console.log("nonshuffled", words40Array.current)
    shuffleArray(words40Array.current)
    //console.log("wordsShuffled40",words40Array.current)
    increaseRoundsCounter()

    setStartButtonShow(false)
    setShowWordDisplay(true)
  }


  const hideWordDisplayAndShowWordButtons = () => {
    setShowWordDisplay(false)
    setWordPadShow(true)
  }


  const generate5And35And40RandomWords = () => {
    //This function generates the 5 unrepeated correct words from master array
    //Then generates 35 unrepeated incorrect words from trimmed down copy of master array
    //Then generates 40 unrepeated array joining correct and incorrect words.
    words5Array.current = []
    words35Array.current = []
    words40Array.current = []
    const copyOfMasterArray = wordsList.slice()

    //Generate 5 correct words from Master word array and store in localstorage
    const storedWordArray5 = localStorage.getItem('wordArray5');
    if (!storedWordArray5){
      for(let i= 0; i<5; i++){
          let arr = copyOfMasterArray[Math.floor(Math.random()*copyOfMasterArray.length)];
          let index = copyOfMasterArray.indexOf(arr);
          copyOfMasterArray.splice(index, 1 );
          words5Array.current.push(arr)
        }
        saveLocalStorage5(words5Array.current)
    } else {
      words5Array.current = retrieveLocalStorage5()
    }

    //Generate 35 Incorrect words from remaning array of 2033 words
    const storedWordArray35 = localStorage.getItem('wordArray35');
    if (!storedWordArray35){
      for(let i= 0; i<35; i++){
        let arr = copyOfMasterArray[Math.floor(Math.random()*copyOfMasterArray.length)];
        let index = copyOfMasterArray.indexOf(arr);
        copyOfMasterArray.splice(index, 1 );
        words35Array.current.push(arr)
      }
      saveLocalStorage35(words35Array.current)
    } else {
      words35Array.current = retrieveLocalStorage35()
    }

    words40Array.current = words5Array.current.concat(words35Array.current)
    //console.log("copy of Master", copyOfMasterArray)
    //console.log("words35Array",words35Array)
    //console.log("words40Array", words40Array)
  }


  const shuffleArray = (array) => {
    //Randomize the order of 40 word array
    return array.sort((a, b) => 0.5 - Math.random());
  }


  const headerAndStartButtonDisplay = () => {
    return (
      <div>
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container"> <h1>記憶力テスト（前半）<small>練習問題</small></h1></div>
          <div className="wordbuttons__ok-button-container"></div>
        </div>
        <button className="wordbuttons__ok-button" onClick={()=> handleStartButton()}>5個の単語表示へ</button>
      </div>
    )
  }


  return (
    <>
    {testFinished ? <TestCompleted score={score}/> :
      showInstructions ? <TestInstructions hideInstructions={hideInstructions}/> :
      <>
        {/* <WordContext.Provider value={wordContextValue}> */}
        <ProgressBar testProgressBar={testProgressBar}/>

        {/* <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>記憶力テスト（前半）<small>練習問題</small></h1>
          </div>
        </div> */}

          <div className="score-info ">
          {user.is_admin && <div className="total-score ">Total Score: {score}/{totalPossibleScore}</div>}
          {/* <div className="total-rounds">Current Round: {rounds} of 1</div> */}
          </div>
            {startButtonShow && headerAndStartButtonDisplay()}
          {showWordDisplay && <WordDisplay words5Array={words5Array.current} hideWordDisplayAndShowWordButtons={hideWordDisplayAndShowWordButtons}/>}
          {wordPadShow && <Wordbuttons setHasPressedOk={setHasPressedOk} hasPressedOk={hasPressedOk} words40={words40Array.current} words5Array={words5Array.current} handleOkButton={handleOkButton} userAnswerArray={userAnswerArray} handleUserWordsInput={handleUserWordsInput}/>}
        {/* </WordContext.Provider> */}
    </>
  }
  </>
  )
}
