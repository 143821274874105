import React, { useState, useRef, useEffect } from "react";
import "./stroop-parta-test.css";
import TestInstructions from "../TestInstructions/TestInstructions";
import TestCompleted from "../TestCompleted/TestCompleted";
import { useAuthState } from "../../../../Context";
import ProgressBar from "../../../ProgressBar/ProgressBar";
let testProgressBar = 1;

export default function StoopPartATest() {
  const userDetails = useAuthState();
  const user = userDetails.user;
  let [circleColor, setCircleColor] = useState({ background: "red" });
  let [correctScore, setCorrectScore] = useState(0);
  let [incorrectScore, setIncorrectScore] = useState(0);
  let [currentRound, setCurrentRound] = useState(1);
  const [showInstructions, setShowInstructions] = useState(true);
  const [testFinished, setTestFinished] = useState(false);
  const [timer, setTimer] = useState(60);
  let colorRandom = useRef();
  const colors = ["blue", "yellow", "black", "green", "red"];

  const randomizeColor = (colorsArray) => {
    //Choose a random color from colors array
    const randomColor =
      colorsArray[Math.floor(Math.random() * colorsArray.length)];
    return randomColor;
  };

  const applyColorToCircle = () => {
    const no_prev_color = colors.filter((e) => e !== circleColor["background"]);
    colorRandom.current = randomizeColor(no_prev_color);
    setCircleColor({ background: colorRandom.current });
  };

  useEffect(() => {
    startRound();
    // return () => {
    //   cleanup
    // }
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (timer === 0 && showInstructions === false) {
        //Prevents time running out and test ending while Instructions are displayed.
        endTest();
      }
      if (timer > 0 && testFinished === false) {
        // Stops countdown on the Otsukaresama page.
        // console.log("Timer", timer)
        const countdown = () => setTimeout(() => setTimer(timer - 1), 1000);
        const timerId = countdown();
        return () => {
          clearTimeout(timerId);
        };
      }
    },
    // eslint-disable-next-line
    [timer]
  );

  const hideInstructions = () => {
    setShowInstructions(false);
    setTimer(60);
  };

  const endTest = () => {
    setTestFinished(true);
    //console.log("Part A TEST if finished")
  };

  const handleAnswerClick = (selectedAnswer) => {
    //console.log("SelectedAnswer", selectedAnswer)
    //console.log("colorRandom.current in handle", colorRandom.current)
    if (colorRandom.current === selectedAnswer) {
      setCorrectScore((correctScore = correctScore + 1));
    } else {
      setIncorrectScore((incorrectScore = incorrectScore + 1));
    }
    ////console.log("CorrectScore", correctScore)
    //console.log("incorrectScore", incorrectScore)
    setCurrentRound((currentRound = currentRound + 1));
    if (currentRound > 100) {
      endTest();
    }
    startRound();
  };

  const startRound = () => {
    applyColorToCircle();
  };

  return (
    <>
      {testFinished ? (
        <TestCompleted
          correctScore={correctScore}
          incorrectScore={incorrectScore}
          currentRound={currentRound}
        />
      ) : showInstructions ? (
        <TestInstructions hideInstructions={hideInstructions} />
      ) : (
        <>
          <ProgressBar testProgressBar={testProgressBar} />
          {/* <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>注意力テスト（前半）</h1>
          </div>
        </div> */}

          <div className="score-info ">
            <span>
              {user.is_admin && (
                <div className="total-score ">
                  Score: <span style={{ color: "blue" }}>{correctScore} </span>|{" "}
                  <span style={{ color: "red" }}>{incorrectScore} </span>
                </div>
              )}
              {user.is_admin && (
                <div className="total-games">
                  ラウンド: {currentRound} / 100
                </div>
              )}
              <div className="total-games">残り時間: {timer}</div>
            </span>
          </div>
          <div>
            <div className="dot" style={circleColor}></div>
          </div>
          <div className="buttonsContainer">
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("blue")}
              >
                あお
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("yellow")}
              >
                きいろ
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("black")}
              >
                くろ
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("green")}
              >
                みどり
              </button>
            </div>
            <div className="colorButtonContainer">
              <button
                className="answerButton"
                onClick={() => handleAnswerClick("red")}
              >
                あか
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
