import React, { useEffect } from "react";
import "./test-completed.css";
import { Link } from "react-router-dom";
import { useAuthState } from "../../../../Context";
import ProgressBar from "../../../ProgressBar/ProgressBar";
let testProgressBar = 0;

export default function TestCompleted({ score }) {
  const userDetails = useAuthState();
  const user = userDetails.user;

  useEffect(() => {
    localStorage.removeItem("wordArray5");
    localStorage.removeItem("wordArray35");
  }, []);

  return (
    <>
      <ProgressBar testProgressBar={testProgressBar} />
      <div className="color-test-completed">
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>
              記憶力テスト（前半）<small>練習問題</small>
            </h1>
          </div>
        </div>

        <div className="text-box">
          {user.is_admin && (
            <p className="instructions-text-center">
              Your Total Score is: {score}
            </p>
          )}
          <div className="instructions-text-center">
            <b>これで練習問題は終了です。</b>
          </div>
        </div>


        <div className="startText">
          実際のテストに進みます。
        </div>
        {/* <Link to={{pathname:"/results", resultsRef : resultsRef }}>View Results</Link> */}
        {/* <Link to={{pathname:"/results", className="continue-button", state:{results : {results}}}}>View Results</Link> */}
        <Link to="/test/word-memory" className="continue-button">
          <h2>次へ</h2>
        </Link>
        {/* <button className="continue-button" >Continue</button> */}
      </div>
    </>
  );
}
