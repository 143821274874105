import Cookies from 'js-cookie';

export async function loginUser(dispatch, loginPayload) {
	const requestOptions = {
		method: 'POST',
		headers: {
      'Content-Type': 'application/json',
      },
    credentials: 'include',
		body: JSON.stringify(loginPayload),
	};

	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let response = await fetch('/api/signin', requestOptions);
		let data = await response.json();
		if (data.user) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: data });
			localStorage.setItem('currentUser', JSON.stringify(data));
			return data;
		}

		dispatch({ type: 'LOGIN_ERROR', error: data.error });
		console.log(data.error);
		return;
	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log(error);
	}
}

export async function loginCrossUser(dispatch, loginPayload) {
	const requestOptions = {
		method: 'POST',
		headers: {
      'Content-Type': 'application/json',
      },
    credentials: 'include',
		body: JSON.stringify(loginPayload),
	};

	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let response = await fetch('/api/cross-login', requestOptions);
		let data = await response.json();
		if (data.user) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: data });
			localStorage.setItem('currentUser', JSON.stringify(data));
			return data;
		}

		dispatch({ type: 'LOGIN_ERROR', error: data.error });
		console.log(data.error);
		return;
	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log(error);
	}
}


export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' });
  await fetch('/api/logout');
	localStorage.removeItem('currentUser');
}

export async function newVisit(dispatch) {
  let csrftoken = Cookies.get('csrf_refresh_token');
	const requestOptions = {
		method: 'POST',
		headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "X-CSRFToken": csrftoken
        },
    credentials: 'include',
		body: JSON.stringify(''),
	};

	try {
		let response = await fetch('/api/visit', requestOptions);
		let data = await response.json();
		if (data.user) {
			dispatch({ type: 'NEW_VISIT', payload: data });
			return data;
		}
    if (data.error) {
      console.log("error", data.error);
      return;
    }
	} catch (error) {
		console.log("error", error);
    logout(dispatch);
	}
}

