import React, { useState } from 'react'
import './number-test-number-pad.css'
import Backspace from '../../../../Images/Backspace.svg'

export default function NumberTestNumberPad({ randomNumberArray, handleOkButton, disablingButton }) {

  const [userNumberDisplay, setUserNumberDisplay] = useState('')


  const handleNumberButtonClick = (number) => {
    //Handles all numeric buttons and backspace (except OK button)
    let newNumberString = userNumberDisplay;
    if (number === 'B' && newNumberString !== ''){
      let editedNumber = newNumberString.slice(0, -1)
      setUserNumberDisplay(editedNumber)
      return
    } else if (number === 'B' && newNumberString === ''){
      return
    }
    newNumberString = userNumberDisplay + number
    setUserNumberDisplay(newNumberString)
  }

  //console.log("NEW randomNumberArray",randomNumberArray)


  return (
    <>
    <div>
      {/* <div className="hint-container">
        「ヒント」正解は<b>「{randomNumberArray.join(",")}」</b>です。<br></br>
        ※注意点：実際のテストでこのヒントボックスが表示されず、サウンドも再生されません。
      </div> */}
      <div>
        <input className="number-display-input" onChange={()=>{}} value={userNumberDisplay}></input>
      </div>
      <div className="number-buttons">
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("1")}>1️</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("2")}>2</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("3")}>3</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("4")}>4</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("5")}>5</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("6")}>6</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("7")}>7</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("8")}>8</button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("9")}>9</button>
        <button disabled={disablingButton} className="number-buttons__backspace" onClick={() => handleNumberButtonClick("B")}><img className="backspaceOnButton" src={Backspace} alt="Backspace" height="40" width="40"/></button>
        <button disabled={disablingButton} className="number-buttons__" onClick={() => handleNumberButtonClick("0")}>0</button>
        <button disabled={disablingButton} className="number-buttons__ok" onClick={() => handleOkButton(userNumberDisplay)}>OK</button>
      </div>
    </div>
    </>
  )
}
