import React, { useReducer, useState, useEffect } from 'react';
import { initialState, AuthReducer } from './reducer';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}
  // console.log("context", context)
  // if (context.user === '') {
  //   context.user = {"has_bd":true, "has_gender":true, "user_id":1000000}
  // }
	return context;
}

export function useAuthDispatch() {
	const context = React.useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within a AuthProvider');
	}

	return context;
}

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);
  const [isBusy, setBusy] = useState(!user.user);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
              "Content-Type": "application/json; charset=UTF-8",
          },
      credentials: 'include',
    };
    fetch('/api/token', requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.user) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: data });
        localStorage.setItem('currentUser', JSON.stringify(data));
        setBusy(false);
        return data;
      }
      setBusy(false);
      return;
    }).catch(err=>{
      console.log("err", err);
      dispatch({ type: 'LOGOUT' });
      setBusy(false);
      return;
    })
  }, [])


  return (
    <>
    {!isBusy && (
      <AuthStateContext.Provider value={user}>
        <AuthDispatchContext.Provider value={dispatch}>
          {children}
        </AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    )}
    </>
	);
};
