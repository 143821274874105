import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { loginUser, useAuthState, useAuthDispatch, loginCrossUser } from "../Context";
import './login.css';
import HeaderLogo from '../Components/HeaderLogo/HeaderLogo'

export const Login = (props) => {
  const { search } = useLocation();
  const { id, eqtid } = queryString.parse(search);
	const dispatch = useAuthDispatch();

  const redirectUser = (user) => {
    if (user.has_bd && user.has_gender) {
			props.history.push('/tests');
    } else {
      props.history.push('/personal-info');
    }
  }

  useEffect(() => {
    const fetchData = async (id, eqtid) => {
      const data = await loginCrossUser(dispatch, { id, eqtid });
      if (!data.user) return;
      redirectUser(data.user);
    }
    if (id && eqtid) {
      fetchData(id, eqtid)
      .catch(console.error)
  }
  // eslint-disable-next-line
  }, [])

  const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const { loading, errorMessage } = useAuthState();

	const handleLogin = async (e) => {
	    e.preventDefault();

		try {
			let response = await loginUser(dispatch, { email, password });
			if (!response.user) return;
      redirectUser(response.user);
		} catch (error) {
			console.log(error);
		}
	};

    return (
      <>
        <HeaderLogo />
        <div className="login-box-container">
        <form  className="login-box">
            <h3 className="login_color">ログイン</h3>
            {
                errorMessage && <p className="error">{errorMessage}</p>
            }

        <div className="email-label-container">
            <label className="email-label">メールアドレス</label>
        </div>
            <input
                className="email-input"
                type="email"
                placeholder="メールアドレス"
                required value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
            />

        <div className="password-label-container">
            <label className="password-label">パスワード</label>
        </div>
            <input className="password-input"
                type="password"
                placeholder="半角英数字8-32文字"
                required value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
            />

            <button className="login-button" type="submit" onClick={handleLogin} disabled={loading}>ログイン</button>
        </form>
        </div>

        <h4>未登録の方は<Link to='/signup'>サインアップ</Link>してください</h4>
      </>
    );
}
