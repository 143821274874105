import React, { useState } from 'react'
import Button from '../Button/button'
import './wordbuttons.css'

export default function Wordbuttons({ words40, words5Array, handleOkButton, userAnswerArray, handleUserWordsInput }) {
  let [amountWordsPressed, setAmountWordsPressed] = useState(0)
  const [hasChosen5Words, setHasChosen5Words] = useState(false)  //This state is above Button.js component. This is because when reaching 15 word array length, other buttons that are not re-rendered at that moment don't know about this so they remain clickable. By setting the state one level above all buttons they all get re-rendered with the latest length of the word array on every click of button. In essence a button click rerenders all buttons again, instead that single button only.
  const [hasPressedKakunin, setHasPressedKakunin] = useState(false)
  const [incorrectAnswersExist, setIncorrectAnswerExist] = useState(false)


  const handleNumberWordsPressed = () => {
    setAmountWordsPressed(userAnswerArray.length)
  }


  // const buttonsElements = words40.map(word => {
  //   return  <Button key={word.id} word={word} hasPressedKakunin={hasPressedKakunin} words5Array={words5Array} handleNumberWordsPressed={handleNumberWordsPressed} userAnswerArray={userAnswerArray} hasChosen5Words={hasChosen5Words} setHasChosen5Words={setHasChosen5Words} handleUserWordsInput={handleUserWordsInput}/>
  // })

  // const buttonsElementsAnswers = words40.map(word => {
  //   return  <Button key={word.id} word={word} hasPressedKakunin={hasPressedKakunin} words5Array={words5Array} handleNumberWordsPressed={handleNumberWordsPressed} userAnswerArray={userAnswerArray} hasChosen5Words={hasChosen5Words} setHasChosen5Words={setHasChosen5Words} handleUserWordsInput={handleUserWordsInput}/>
  // })


    const buttonsElements = words40.map(word => {
    return  <Button key={word} word={word} hasPressedKakunin={hasPressedKakunin} words5Array={words5Array} handleNumberWordsPressed={handleNumberWordsPressed} userAnswerArray={userAnswerArray} hasChosen5Words={hasChosen5Words} setHasChosen5Words={setHasChosen5Words} handleUserWordsInput={handleUserWordsInput}/>
  })


  const buttonsElementsAnswers = words40.map(word => {
    return  <Button key={word} word={word} hasPressedKakunin={hasPressedKakunin} words5Array={words5Array} handleNumberWordsPressed={handleNumberWordsPressed} userAnswerArray={userAnswerArray} hasChosen5Words={hasChosen5Words} setHasChosen5Words={setHasChosen5Words} handleUserWordsInput={handleUserWordsInput}/>
  })


  const hasFinishedPressingWordButtons = () => {
    setHasPressedKakunin(true)
    let checker = (arr, target) => target.every(v => arr.includes(v)); //checks that target array exists within arr
    if (!checker(userAnswerArray, words5Array)){
      setIncorrectAnswerExist(true)
    }
  }

  //let correctWords = retrieveLocalStorage5()
  ////console.log("CorrectWords", correctWords)

    return (
      <>
        {/* <h3>最初に表示された単語を選んでください</h3> */}
        {/* <div className="hint-container">          「ヒント」正しい単語は<b>「{correctWords.join(",")}」</b>です。<br></br>          ※注意点：実際のテストでこのヒントボックスが表示されません。        </div> */}
        {!hasPressedKakunin ?
          <>
            <div className="wordbuttons__buttons-pad">
              {buttonsElements}
            </div>

            <div className='wordbuttons__ok-button-container'>
              {amountWordsPressed === 5 && <button className='wordbuttons__ok-button' onClick={()=> {hasFinishedPressingWordButtons()}}>確認</button>}
            </div>

            <div className="wordbuttons__selected-button-number-display">
              <span>{amountWordsPressed}/5</span>
            </div>
          </> :
          <>
            {incorrectAnswersExist && <div className="incorrectWordsMessage">不正解がありました</div>}
            {!incorrectAnswersExist && <div className="correctWordsMessage"> 全問正解でした</div>}
            <div className="wordbuttons__buttons-pad">
              {buttonsElementsAnswers}
            </div>

            <div className='wordbuttons__ok-button-container'>
              {amountWordsPressed === 5 && <button className='wordbuttons__ok-button' onClick={()=> {handleOkButton()}}>終了</button>}
            </div>

            <div className="wordbuttons__selected-button-number-display">
              <span>{amountWordsPressed}/5</span>
            </div>
          </>
        }
      </>
    )
}
