import React from "react";
import "./test-completed.css";
import { Link } from "react-router-dom";
import { useAuthState } from "../../../../Context";
import ProgressBar from "../../../ProgressBar/ProgressBar";
let testProgressBar = 1;

export default function TestCompleted({
  correctScore,
  incorrectScore,
  currentRound,
}) {
  const userDetails = useAuthState();
  const user = userDetails.user;

  return (
    <>
      <ProgressBar testProgressBar={testProgressBar} />
      <div className="color-test-completed">
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>
              注意力テスト（前半）<small>練習問題</small>
            </h1>
          </div>
        </div>

        <div className="text-box">
          {user.is_admin && (
          <p className="instructions-text-center">
            Your Total Correct Score is:
            <span style={{ color: "blue" }}>{correctScore} </span>
          </p>
        )}
        {user.is_admin && (
          <p className="instructions-text-center">
            Your Total Incorrect Score is:
            <span style={{ color: "red" }}>{incorrectScore} </span>
          </p>
        )}
          <div className="instructions-text-center">
            <b>これで練習問題は終了です。</b>
          </div>
        </div>


        <div className="startText">
        実際のテストに進みます。
        </div>


        <Link to="/test/stroop-test-a" className="continue-button">
          <h2>次へ</h2>
        </Link>
        {/* <button className="continue-button" >Continue</button> */}
      </div>
    </>
  );
}
