// Import required dependencies and CSS
import React, { useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import validator from 'validator'
import './login.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TermsModal from "./TermsModal";
import HeaderLogo from '../Components/HeaderLogo/HeaderLogo'

// Define SignUp component
export const SignUp = () => {
  // Initialize state variables and hooks
  const history = useHistory();
  const [addEmail, setAddEmail] = useState('')
  const [addName, setAddName] = useState('')
  const [addKanjiName, setAddKanjiName] = useState('')
  const [addPassword, setAddPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [gender, setGender] = useState(false);
  const [birthDate, setBirthDate] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // RadioButton component for gender selection
  const RadioButton = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="radio" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };

  // Handlers for changing gender selection
  const handleMaleChange = () => {
    setGender('male');
  };

  const handleFemaleChange = () => {
    setGender('female');
  };

  const handleOtherChange = () => {
    setGender('other');
  };

  const handleCheckboxChange = () => {
    if (isChecked) {
      setIsChecked(false)
    } else {
      setIsChecked(true)
    }
  };

  // Function to validate password strength
  const validate = (value) => {
    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 0, minNumbers: 1, minSymbols: 0
    })) {
      setErrorMessage('')
    } else {
      setErrorMessage('半角英数字8-32文字')
    }
  }

  // Delay function for hiding error message
  const delay = ms => new Promise(res => setTimeout(res, ms));

  // Function to hide error message after a delay
  const disappearErrorMessageCounter = async () => {
    await delay(3000);
    setErrorMessage('')
  };

  // Handle form submission
  const handleFormSubmit = (event) => {
      event.preventDefault();
      fetch('/api/signup', {
      method: 'POST',
      body: JSON.stringify({
          patientId: null,
          email:addEmail,
          name:addName,
          kanjiName:addKanjiName,
          password:addPassword,
          gender:gender,
          birthDate:birthDate,
          consent:isChecked,
      }),
      headers: {
          "Content-Type": "application/json; charset=UTF-8"
      }
      }).then(response => response.json())
      .then(data => {
        //console.log("signup log", data)
        if (data.success) {
          history.push('/login')
        } else {
          setErrorMessage(data.error)
          disappearErrorMessageCounter()
        }
        setAddPassword('')
      })
      .catch(err=>{
        console.log("err", err);
        })
  }

  // Render SignUp component
  return (
  <>
    <HeaderLogo />
    <div className="signup-box-container">
      <form className="signup-box" onSubmit={handleFormSubmit}>
          <h3 className="login_color">サインアップ</h3>
          <div className="signup-kanji-name-label-container">
            <label>氏名</label>
          </div>
          <input
              className="email-input"
              type="text"
              placeholder="氏名"
              onInvalid={(e) => e.preventDefault()}  //This line in all requires prevents to show the "please fill out this field" browser message when clicking on the TOS modal
              required
              value={addKanjiName}
              onChange={(e) => setAddKanjiName(e.target.value)}
          />

          <div className="signup-name-label-container">
            <label>氏名（ふりがな）</label>
          </div>
          <input
              className="email-input"
              type="text"
              placeholder="氏名（ふりがな）"
              onInvalid={(e) => e.preventDefault()}
              required
              value={addName}
              onChange={(e) => setAddName(e.target.value)}
          />

          <div className="signup-gender-label-container">
            <label>性別</label>
            </div>
            <div className="gender-radio-buttons-container">
            <RadioButton
              label="男性"
              value={gender === 'male'}
              onChange={handleMaleChange}
            />
            <RadioButton
              label="女性"
              value={gender === 'female'}
              onChange={handleFemaleChange}
            />
            <RadioButton
              label="その他"
              value={gender === 'other'}
              onChange={handleOtherChange}
            />
          </div>

          <div className="w100p">
            <label className="signup-dob-label-container">生年月日</label>
            <DatePicker classname="react-datepicker__input-container"
            selected={birthDate}
            onChange={(date) => setBirthDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="DD/MM/YYYY"
            maxDate={new Date()}
            />
          </div>

          <div className="signup-email-label-container">
            <label>メールアドレス</label>
          </div>
          <input
              className="email-input"
              type="text"
              placeholder="メールアドレス"
              onInvalid={(e) => e.preventDefault()}
              required
              value={addEmail}
              onChange={(e) => setAddEmail(e.target.value)}
          />

          <div className="signup-password-label-container">
            <label>パスワード</label>
          </div>
          <input
              className="password-input"
              type="password"
              placeholder="(半角英数字8-32文字)"
              onInvalid={(e) => e.preventDefault()}
              required
              value={addPassword}
              onInput={(e) => setAddPassword(e.target.value)}
              onChange={(e) => validate(e.target.value)}
              />

          <div className="signup-terms-label-container">
            <label>会員規約 <span style={{ color: 'red' }}>(必須)</span></label>
            <button className="link-button" onClick={() => setIsModalOpen(true)}>会員規約を確認する</button>
            <div>
              {isModalOpen && (
                <div className="modal-overlay">
                  <div className="modal">
                    <button className="modal-close" onClick={() => setIsModalOpen(false)}>×</button>
                    <TermsModal onClose={() => setIsModalOpen(false)} />
                  </div>
                </div>
              )}
            </div>
            <div className="accept-terms-checkbox-label-container">
            <input type="checkbox" onChange={handleCheckboxChange} onInvalid={(e) => e.preventDefault()} required />
              <label>会員規約に同意する</label>
            </div>
            <div style={{fontWeight: "normal"}}>
              30代~70代以外の方は現在偏差値判定をご利用いただけません。<Link to="/welcome"> トップ画面</Link>にお戻りいただき、「今すぐテスト開始」よりご利用ください。
            </div>
          </div>

          <span className="signup-error-message-container">{errorMessage}</span>

          {!errorMessage && addEmail && addName && addKanjiName && birthDate && gender && isChecked ?
            <button className="login-signup-button" type="submit">サインアップ</button>
          :
            <button className="login-signup-button-disabled" type="submit" disabled>サインアップ</button>
          }
        </form>
      </div>
      <h4>登録済みの方は<Link to='/login'>ログイン</Link></h4>
  </>);
}
