import React, { useState, useEffect, useRef, createContext } from 'react';
import Cookies from 'js-cookie';
import WordDisplay from '../WordDisplay/worddisplay'
import Wordbuttons from '../Wordbuttons/wordbuttons';
import TestInstructions from '../TestInstructions/TestInstructions';
import TestCompleted from '../TestCompleted/TestCompleted';
import './wordmemorytest.css';
import { wordsList } from '../../../wordsList'
import { useAuthState, logout, useAuthDispatch } from '../../../Context';
import ProgressBar from '../../ProgressBar/ProgressBar';
let testProgressBar = 0

export const WordContext = createContext()

export default function WordMemoryTest() {

	const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const user = userDetails.user;

  const [startButtonShow, setStartButtonShow] = useState(true)
  const [wordPadShow, setWordPadShow] = useState(false)
  const [showWordDisplay, setShowWordDisplay] = useState(false)
  const [showInstructions, setShowInstructions] = useState(true)
  const [testFinished, setTestFinished] = useState(false)
  let [score, setScore] = useState(0)
  let [totalPossibleScore, setTotalPossibleScore] = useState(0)
  let [rounds, setRounds] = useState(0)
  let words15Array = useRef([])
  let words25Array = useRef([])
  let words40Array = useRef([])
  let userAnswerArray = []

  useEffect(() => {
    generate15And25And40RandomWords()
    // eslint-disable-next-line
  },[])

  const saveLocalStorage15 = (wordArray) => {
    localStorage["wordArray15"] = JSON.stringify(wordArray);
  }

  const saveLocalStorage25 = (wordArray) => {
    localStorage["wordArray25"] = JSON.stringify(wordArray);
  }

  const retrieveLocalStorage15 = () => {
    let storedArray = JSON.parse(localStorage["wordArray15"]);
    //console.log("storedArray", storedArray)
    return storedArray
  }

  const retrieveLocalStorage25 = () => {
    let storedArray = JSON.parse(localStorage["wordArray25"]);
    //console.log("storedArray", storedArray)
    return storedArray
  }

  const handleResult = (round, score) => {
  let csrftoken = Cookies.get('csrf_refresh_token');
  const requestOptions = {
		method: 'POST',
		headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "X-CSRFToken": csrftoken
        },
    credentials: 'include',
    body: JSON.stringify({
        round:round,
        score:score
    }),
	};
  fetch('/api/test/word_memory', requestOptions)
  .then(response => response.json())
  .catch(err=>{
    console.log("err", err);
    logout(dispatch);
    })
  }

  const endTest = () => {
    setTestFinished(true)
  }

  const hideInstructions = () => {
    setShowInstructions(false)
  }

  const handleUserWordsInput = (word) => {
    if (userAnswerArray.includes(word)) {
      const index = userAnswerArray.indexOf(word);
      if (index > -1) {
        userAnswerArray.splice(index, 1);
      }
    } else {
      userAnswerArray.push(word)
    }
    //console.log("userAnswerArray",userAnswerArray)
  }


  const handleOkButton = () => {
    //Calculate correct words in user answer array.
    const correctAnswers = words15Array.current.filter(e => userAnswerArray.indexOf(e) !== -1);
    ////console.log("CorrectAnswers",correctAnswers)
    //Update score
    setScore(score = score + correctAnswers.length)
    setTotalPossibleScore(totalPossibleScore = totalPossibleScore + 15)
    // Record score
    handleResult(rounds, correctAnswers.length)
    //If completed round 1 then finish test
    if(rounds >= 1) {
      endTest()
    } else {
      setShowWordDisplay(false)
      setWordPadShow(false)
      setStartButtonShow(true)
    }
  }


  const increaseRoundsCounter = () => {
    setRounds(rounds = rounds + 1)
  }


  const handleStartButton = () => {
    generate15And25And40RandomWords()
    //console.log("15correctWords", words15Array.current)
    //console.log("nonshuffled", words40Array.current)
    shuffleArray(words40Array.current)
    //console.log("wordsShuffled40",words40Array.current)
    increaseRoundsCounter()

    setStartButtonShow(false)
    setShowWordDisplay(true)
  }


  const hideWordDisplayAndShowWordButtons = () => {
    setShowWordDisplay(false)
    setWordPadShow(true)
  }


  const generate15And25And40RandomWords = () => {
    //This function generates the 15 unrepeated correct words from master array
    //Then generates 25 unrepeated incorrect words from trimmed down copy of master array
    //Then generates 40 unrepeated array joining correct and incorrect words.
    words15Array.current = []
    words25Array.current = []
    words40Array.current = []
    const copyOfMasterArray = wordsList.slice()

    //Generate 15 correct words from Master word array and store in localstorage
    const storedWordArray15 = localStorage.getItem('wordArray15');
    if (!storedWordArray15){
      for(let i= 0; i<15; i++){
          let arr = copyOfMasterArray[Math.floor(Math.random()*copyOfMasterArray.length)];
          let index = copyOfMasterArray.indexOf(arr);
          copyOfMasterArray.splice(index, 1 );
          words15Array.current.push(arr)
        }
        saveLocalStorage15(words15Array.current)
    } else {
      words15Array.current = retrieveLocalStorage15()
    }

    //Generate 25 Incorrect words from remaning array of 2033 words
    const storedWordArray25 = localStorage.getItem('wordArray25');
    if (!storedWordArray25){
      for(let i= 0; i<25; i++){
        let arr = copyOfMasterArray[Math.floor(Math.random()*copyOfMasterArray.length)];
        let index = copyOfMasterArray.indexOf(arr);
        copyOfMasterArray.splice(index, 1 );
        words25Array.current.push(arr)
      }
      saveLocalStorage25(words25Array.current)
    } else {
      words25Array.current = retrieveLocalStorage25()
    }

    words40Array.current = words15Array.current.concat(words25Array.current)
    //console.log("copy of Master", copyOfMasterArray)
    //console.log("words25Array",words25Array)
    //console.log("words40Array", words40Array)
  }


  const shuffleArray = (array) => {
    //Randomize the order of 40 word array
    return array.sort((a, b) => 0.5 - Math.random());
  }


  // const wordContextValue = {
  //   handleUserWordsInput
  // }


  const headerAndStartButtonDisplay = () => {
    return (
      <div>
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>記憶力テスト（前半）</h1>
          </div>
        </div>
        <button className="wordbuttons__ok-button" onClick={()=> handleStartButton()}>15個の単語表示へ</button>
      </div>
    )
  }


  return (
    <>
    {testFinished ? <TestCompleted score={score}/> :
      showInstructions ? <TestInstructions hideInstructions={hideInstructions}/> :
      <>
        <ProgressBar testProgressBar={testProgressBar}/>
        {/* <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>記憶力テスト（前半）</h1>
          </div>
        </div> */}
          <div className="score-info ">
          {user.is_admin && <div className="total-score ">Total Score: {score}/{totalPossibleScore}</div>}
          {/* <div className="total-rounds">Current Round: {rounds} of 1</div> */}
          </div>
          <div className="wordbuttons__ok-button-container">
            {startButtonShow && headerAndStartButtonDisplay()}
          </div>
          {showWordDisplay && <WordDisplay words15Array={words15Array.current} hideWordDisplayAndShowWordButtons={hideWordDisplayAndShowWordButtons}/>}
          {wordPadShow && <Wordbuttons words40={words40Array.current} handleOkButton={handleOkButton} userAnswerArray={userAnswerArray} handleUserWordsInput={handleUserWordsInput}/>}
        {/* </WordContext.Provider> */}
    </>
  }
  </>
  )
}
