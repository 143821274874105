import React from 'react'
import header_logo from '../../Images/Logo_Horizontal.png'

export default function HeaderLogo() {
  return (
    <>
      <div className="header-logo-container">
        <img src={header_logo} alt="Logo" className="header-logo" />
      </div>
      <div className="header-spacer"></div>
    </>
  )
}
