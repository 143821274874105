import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import NumberTestDisplay from '../NumberTestReverseDisplay/NumberTestReverseDisplay';
import NumberTestNumberPad from '../NumberTestReverseNumberPad/NumberTestReverseNumberPad';
import TestInstructions from '../TestInstructions/TestInstructions'
import TestCompleted from '../TestCompleted/TestCompleted'
import { useAuthState, logout, useAuthDispatch } from '../../../Context';
import './number-test-reverse.css'
import ProgressBar from '../../ProgressBar/ProgressBar';

let testProgressBar = 4


export default function NumberTestReverse() {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const user = userDetails.user;
  const [showNumbers, setShowNumbers] = useState(false);
  const [startButtonShow, setStartButtonShow] = useState(true)
  const [showNumberPad, setShowNumberPad] = useState(false)
  const [showInstructions, setShowInstructions] = useState(true)
  const [testFinished, setTestFinished] = useState(false)
  let [score, setScore] = useState(0)
  let [rounds, setRounds] = useState(1)
  let [wrongCounter, setWrongCounter] = useState(0)
  let amountOfNumbersToDisplay = useRef(2)
  let randomNumberArray = useRef([])


  useEffect(() => {
    randomNumberArray.current = randomizeArray(amountOfNumbersToDisplay.current)
    //console.log("use effect game", randomNumberArray.current)
    return () => {
      // clearInterval(interval);
      // clearTimeout(timeout);
    };
    // eslint-disable-next-line
  },[])


  const handleResult = (round, score) => {
    let csrftoken = Cookies.get('csrf_refresh_token');
    const requestOptions = {
		method: 'POST',
		headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "X-CSRFToken": csrftoken
        },
    credentials: 'include',
    body: JSON.stringify({
        round:round,
        score:score
    }),
	  };
    fetch('/api/test/number_memory_bwd', requestOptions)
    .then(response => response.json())
    .catch(err=>{
      console.log("err", err);
      logout(dispatch);
      })
  }


  const endTest = () => {
    setTestFinished(true)
  }


  const hideInstructions = () => {
    setShowInstructions(false)
  }


  const handleStartButton = () => {
    setShowNumbers(true)
    setStartButtonShow(false)
  }


  const hideNumbersAndDisplayPad = () => {
    setShowNumbers(false)
    setShowNumberPad(true)
  }


  const allConsecutives = (arr) =>{
    arr = arr.slice(1).map(function(n, i) { return n - arr[i]; })
    return arr.every(value => value === 1)
  }


  const randomizeArray = (arrayLength) => {
      //Generates an array of random numbers of length equal to arrayLength
      const array = [...Array(10).keys()]
      const shuffledArray = array.sort((a, b) => 0.5 - Math.random());
      let numberArray = shuffledArray.slice(0, arrayLength)

      if (allConsecutives(numberArray)) {
        //console.log("all consecutive retrying", numberArray)
        numberArray = randomizeArray(arrayLength)
      }
      return numberArray
    }


  const handleOkButton = (userInputNumber) => {
    //Prevent empty answer or OK misclick
    if (userInputNumber) {
      //If round 7 then finish test.
      if (rounds >= 7){
        endTest()
      }
      //The OK button will assert right or wrong answer and will start a new round in these cases:
      //If the number was correctly input, move to the next round and increase digits to display by one.  Also clear the wrong answer counter back to 0.
      //If the number was wrong, repeat current round with current number of digits to display.
      //If the number is wrong again, end the test.
      //console.log("userInputNumber from component", userInputNumber)
      let answerArrayString = userInputNumber.split('')
      let answerArrayInt = []
      //console.log("string answerArray", answerArrayString)
      for (let i = 0; i < answerArrayString.length; i++) {
        answerArrayInt.push(parseInt(answerArrayString[i]))
      }
      //console.log("int array", answerArrayInt)
      //console.log("originalArray", randomNumberArray.current)
      // If correct:
      if (JSON.stringify(answerArrayInt.reverse())===JSON.stringify(randomNumberArray.current)) {
        //console.log("Correct!")
        handleResult(rounds + 1, wrongCounter + 1) // # of rounds and score. Score 1: correct the first time, 2: correct from the second time
        setScore(score = score + 1)
        amountOfNumbersToDisplay.current++;
        randomNumberArray.current = randomizeArray(amountOfNumbersToDisplay.current);
        setWrongCounter(0)
        setRounds(rounds = rounds + 1);
        setShowNumbers(true)
        setShowNumberPad(false)
      } else {
        // If incorrect
        //console.log("Incorrect!")
        setWrongCounter(wrongCounter = wrongCounter + 1)
        if (wrongCounter === 2){
          handleResult(rounds + 1, wrongCounter + 1) // # of rounds and score. Score 3: incorrect two times
          endTest()
        }
        randomNumberArray.current = randomizeArray(amountOfNumbersToDisplay.current);
        setShowNumbers(true)
        setShowNumberPad(false)
      }
    }
  }


  const headerAndStartButtonDisplay = () => {
    return (
      <div>
        <ProgressBar testProgressBar={testProgressBar}/>
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>
              処理機能テスト（後半）<small>練習問題</small>
            </h1>
          </div>
        </div>

        <div className="score-info ">
          {user.is_admin && <div className="total-score ">Total Score: {score}</div>}
        <div className="total-rounds">第{rounds}問</div>
        </div>
        {user.is_admin && <div className="total-rounds">Digits to Display: {amountOfNumbersToDisplay.current} </div>}
        {user.is_admin && <div className="total-rounds">WrongCounter: {wrongCounter} </div>}
        <div className="wordbuttons__ok-button-container">
          {startButtonShow && <button className="wordbuttons__ok-button" onClick={()=> handleStartButton()}>数字を表示</button>}
          <div className="startText">
          <br/>
          これから表示される順とは逆の順に数字を選んでください。
          </div>
        </div>
      </div>
    )
  }


  return (
    <>
    {testFinished ? <TestCompleted score={score}/> :
      showInstructions ? <TestInstructions hideInstructions={hideInstructions}/> :
      startButtonShow ? headerAndStartButtonDisplay() :
    <>
      <ProgressBar testProgressBar={testProgressBar}/>
      {/* <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>
              処理機能テスト（後半）
            </h1>
          </div>
        </div>

              <div className="score-info ">
      {user.is_admin && <div className="total-score ">Total Score: {score}</div>}
      <div className="total-rounds">第{rounds}問</div>
      </div>
      {user.is_admin && <div className="total-rounds">Digits to Display: {amountOfNumbersToDisplay.current} </div>}
      {user.is_admin && <div className="total-rounds">WrongCounter: {wrongCounter} </div>} */}
      {/* <div className="wordbuttons__ok-button-container">
        {startButtonShow && <button className="wordbuttons__ok-button" onClick={()=> handleStartButton()}>数字を表示</button>}
      </div> */}
      <div className="number-display-and-number-pad-container">
      {showNumbers && (
          <div>
            <div className="correct-incorrect-answer-display-container">
            <div className="score-info ">{user.is_admin && <div className="total-score ">Total Score: {score}</div>}
                <div className="total-rounds">第{rounds}問</div>
              </div>
            </div>
            <NumberTestDisplay randomNumberArray={randomNumberArray.current} hideNumbersAndDisplayPad={hideNumbersAndDisplayPad}/>
            </div>
        )}

        {showNumberPad && (
          <div>
            <div className="correct-incorrect-answer-display-container">
              <div className="score-info ">{user.is_admin && <div className="total-score ">Total Score: {score}</div>}
                <div className="total-rounds">第{rounds}問</div>
              </div>
            </div>
            <NumberTestNumberPad randomNumberArray={randomNumberArray.current} handleOkButton={handleOkButton}/>
          </div>
        )}

      </div>
    </>
  }
  </>
  )
}
