import React from "react";
import "./test-completed.css";
import { Link } from "react-router-dom";
import { useAuthState } from "../../../Context";
import ProgressBar from "../../ProgressBar/ProgressBar";
import Otsukare from "../../../Images/otsukare@2x.png";

let testProgressBar = 0;

export default function TestCompleted({ score }) {
  const userDetails = useAuthState();
  // eslint-disable-next-line
  const user = userDetails.user;

  return (
    <>
      <ProgressBar testProgressBar={testProgressBar} />
      <div className="color-test-completed">
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>記憶力テスト（前半）</h1>
          </div>
        </div>
        <div className="text-box">
          <div className="instructions-text">{user.is_admin && (<div className="instructions-text-center">Your Total Score is: {score}</div>)}</div>
          <div className="flex">
            <div className="exam-icon">
              <img src={Otsukare} alt="おつかれさまでした" />
            </div>
              <p className="instructions-text-center">
                <b>
                これらの単語は覚えておいてください。後ほど、再び記憶力テストを行います。
                </b>
              </p>
          </div>
        </div>
        <div className="startText">次のテストに進みます。</div>
        {/* <Link to={{pathname:"/results", resultsRef : resultsRef }}>View Results</Link> */}
        {/* <Link to={{pathname:"/results", className="continue-button", state:{results : {results}}}}>View Results</Link> */}
        <Link to="/practice/stroop-test-a" className="continue-button">
          <h2>次へ</h2>
        </Link>
        {/* <button className="continue-button" >Continue</button> */}
      </div>
    </>
  );
}
