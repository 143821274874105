import React from 'react';
import './notfound.css';

function NotFound(props) {
	return (
		<div className="container">
			<h1>Page not found</h1>
		</div>
	);
}

export default NotFound;