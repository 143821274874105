import React, { useEffect, useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { logout, useAuthDispatch, useAuthState } from "../../Context";
import { NewVisit } from '../../Pages/NewVisit';
import HeaderLogo from '../HeaderLogo/HeaderLogo'

export default function WelcomePage() {
  let testing_allowed = useRef(true);

  useEffect(() => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.')
    //parts[0] should be the subdomain if there is any
    if(parts.length > 2) {
      console.log("Subdomain: ", parts[0])
    } else {
      console.log("No subdomain in use")
    }
  }, [])

  return (
    <>
      <HeaderLogo />
      <div className="head welcome-text-button-results-container">
        <div className="welcome-text-container">
          <h1>脳の健康3分チェック</h1>
        </div>
      </div>
      <div className="text-box">
        <p className="centered-text">
          簡単なテストで、記憶をつかさどる「海馬」の健康状態をチェック！
        「脳の健康3分チェック」では、同年代・性別と比較して、あなたの「注意力偏差値」「処理機能偏差値」を確認することができます。所要時間は約3分です。
        </p>
      </div>

      <div className="historic-results-box">
        <div className="historic-results-header">
          <p>会員登録すると・・・<br></br>全国の同年代・性別と比較した偏差値がわかる！</p>
        </div>
        {/* 総合点 */}
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container"><h1>テストの結果例</h1></div>
        </div>
        <div className="score__grid">
          <span className="item1">総合点</span>
          <span className="score"><b>86</b><small>/100</small></span><span className="item3">点</span>
        </div>
        {/* eslint-disable-next-line */}
        <div className="deviation-container" style={{width:60+'%'}}>
          <p className="deviation-text">偏差値 {60}</p>
        </div>
        {/* テスト１ */}
        <div className="score__grid">
          <span className="item1">注意力テスト</span>
          <span className="score"><b>44</b><small>/100</small></span><span className="item3">点</span>
        </div>
        {/* eslint-disable-next-line */}
        <div className="deviation-container" style={{width:40+'%'}}>
          <p className="deviation-text">偏差値 {56}</p>
        </div>
        {/* テスト2 */}
        <div className="score__grid">
          <span className="item1">処理機能テスト</span>
          <span className="score"><b>42</b><small>/100</small></span><span className="item3">点</span>
        </div>
        {/* eslint-disable-next-line */}
        <div className="deviation-container" style={{width:40+'%'}}>
          <p className="deviation-text">偏差値 {46}</p>
        </div>
      </div>

        <Link to='/signup' className="continue-button">
          <h2>会員登録して開始</h2>
        </Link>
      <div className="text-box">
        <p>
          会員登録せずに下のボタンより進むことで、点数のみを確認することもできます。
        </p>
      </div>
        <Link to="/practice/unregistered-stroop-test-b" className="continue-button">
          <h2>今すぐ開始</h2>
        </Link>
        <h4>会員登録済みの方は<Link to='/login'>ログイン</Link></h4>

        <p>※本テストは独自のテスト項目であり、科学的根拠に基づいた<br></br>診断結果を提供するものではございません。</p>
    </>
  )
}
