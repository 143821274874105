import React, { useState } from 'react'
//import { WordContext } from '../WordMemoryTest/wordmemorytest'
import blueCircle from '../../../../Images/Circle.svg'
import redCross from '../../../../Images/Cross.svg'
import './button.css'

export default function Button({word, hasPressedKakunin, words5Array, handleNumberWordsPressed, userAnswerArray, hasChosen5Words, setHasChosen5Words, handleUserWordsInput}) {
  //const {handleUserWordsInput} = useContext(WordContext)
  const [isPressed, setIsPressed] = useState(false)

  const handleChosen5WordsCase = () => {
    userAnswerArray.length >= 5 ? setHasChosen5Words(true) : setHasChosen5Words(false);
  }

  const handleWordClick = (word) => {
    handleUserWordsInput(word)
    isPressed ? setIsPressed(false) : setIsPressed(true)
    //console.log("the word is", word)
    handleNumberWordsPressed()
    handleChosen5WordsCase()
  }

  if (!hasPressedKakunin) {
  //Buttons will only be pressable if word array is below 5. If at 5 then only already pressed buttons are pressable which reduces the length of the array to under 5 and lets all other buttons be pressable again.
    return (
      <>
      <div className="color-button-container">
      <button disabled={hasChosen5Words && !isPressed ? 1 : 0} style = { isPressed ? {background:'#6CA3E3', color:'white'} : {background:'#fff', color:'#333'}} className='button__word-button' onClick={() => {handleWordClick(word)}}>{word}</button>
      </div>
      </>
    )
  } else if (hasPressedKakunin && words5Array.indexOf(word) !== -1 && userAnswerArray.indexOf(word) !== -1) { // Mark circles if selected word is correct. Word exists in useranswer and also in 5word array
    return(
      <div className="color-button-container">
        <img className="circle-on-word-button" src={blueCircle} alt="Circle" height="40" width="40"/>
        {/* {crossOnBlueButton && <img className="crossOnButton" src={redCross} alt="Cross" height="40" width="40"/>} */}
        <button disabled={hasChosen5Words && !isPressed ? 1 : 0} style = {{background:'#6CA3E3', color:'white'}} className='button__word-button' onClick={() => {handleWordClick(word)}}>{word}</button>
      </div>
    )
  } else if (hasPressedKakunin && words5Array.indexOf(word) !== 1 && userAnswerArray.indexOf(word) !== -1) { // If word is in userselected array and not in 5correct array, Mark crosses if selected word is INCORRECT
    return(
      <div className="color-button-container">
        <img className="cross-on-word-button" src={redCross} alt="Cross" height="40" width="40"/>
        <button disabled={hasChosen5Words && !isPressed ? 1 : 0} style = {{background:'#6CA3E3', color:'white'}} className='button__word-button' onClick={() => {handleWordClick(word)}}>{word}</button>
      </div>
    )
  } else if (hasPressedKakunin && words5Array.indexOf(word) !== -1 && userAnswerArray.indexOf(word) !== 1) { // For words that are in 5 correct words but not in user selected (should have been correct)
    return(
      <div className="color-button-container">
        <img className="circle-on-word-button" src={blueCircle} alt="Circle" height="40" width="40"/>
        <button disabled={hasChosen5Words && !isPressed ? 1 : 0} style = { isPressed ? {background:'#6CA3E3', color:'white'} : {background:'#fff', color:'#333'}} className='button__word-button' onClick={() => {handleWordClick(word)}}>{word}</button>
      </div>
    //   <div className="color-button-container">
    //    <button disabled={hasChosen5Words && !isPressed ? 1 : 0} style = {{background:'#2A49C3', color:'white'}} className='button__word-button' onClick={() => {handleWordClick(word)}}>{word}</button>
    //   </div>
    )
  } else if (hasPressedKakunin) { // For words that are not selected or in correct array then Do nothing
      return(
        <div className="color-button-container">
          <button disabled={hasChosen5Words && !isPressed ? 1 : 0} style = { isPressed ? {background:'#6CA3E3', color:'white'} : {background:'#fff', color:'#333'}} className='button__word-button' onClick={() => {handleWordClick(word)}}>{word}</button>
        </div>
      )
  }
}
