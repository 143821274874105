import React, { useEffect } from "react";
import "./test-completed.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useAuthState, logout, useAuthDispatch } from "../../../../Context";
import ProgressBar from "../../../ProgressBar/ProgressBar";
import Otsukare from "../../../../Images/otsukare@2x.png";
let testProgressBar = 1;

export default function TestCompleted({
  correctScore,
  incorrectScore,
  currentRound,
}) {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const user = userDetails.user;

  useEffect(() => {
    let csrftoken = Cookies.get("csrf_refresh_token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRFToken": csrftoken,
      },
      credentials: "include",
      body: JSON.stringify({
        partBCorrect: correctScore,
        partBInCorrect: incorrectScore,
        partBTotal: currentRound,
      }),
    };
    fetch("/api/test/stroop_b", requestOptions)
      .then((response) => response.json())
      .catch((err) => {
        console.log("err", err);
        logout(dispatch);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ProgressBar testProgressBar={testProgressBar} />
      <div className="color-test-completed">
        <div className="head welcome-text-button-results-container">
          <div className="welcome-text-container">
            <h1>注意力テスト（後半）</h1>
          </div>
        </div>

        <div className="text-box">
          <div className="flex">
            <div className="exam-icon">
              <img src={Otsukare} alt="おつかれさまでした" />
            </div>
            <div className="instructions-text">
              <h2 className="instructions-text-center">おつかれさまでした。</h2>
              {user.is_admin && (
                <p className="instructions-text-center">
                  Your Total Correct Score is:{" "}
                  <span style={{ color: "blue" }}>{correctScore} </span>
                </p>
              )}
              {user.is_admin && (
                <p className="instructions-text-center">
                  Your Total Incorrect Score is:{" "}
                  <span style={{ color: "red" }}>{incorrectScore} </span>
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="startText">次のテストに進みます。</div>

        <Link to="/practice/unregistered-number-memory" className="continue-button">
          <h2>次へ</h2>
        </Link>
        {/* <button className="continue-button" >Continue</button> */}
      </div>
    </>
  );
}
