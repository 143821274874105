import React from 'react'
import './progress-bar.css'
import Flow1 from '../../Images/flow1.svg'
import Flow2 from '../../Images/flow2.svg'
import Flow3 from '../../Images/flow3.svg'
import Flow4 from '../../Images/flow4.svg'
import Flow5 from '../../Images/flow5.svg'
import Flow6 from '../../Images/flow6.svg'


// import './w3.css'

export default function ProgressBar({testProgressBar}) {

  //let progressBarPercentage = (testProgressBar * 100)/6
  //console.log("testProgressBar is", testProgressBar)
  return (
    <>
        {/* <div className="w3-light-grey w3-round-medium" style={{width:"50%", display:"inline-block"}} width="40px">
        <div className="w3-container w3-round w3-blue" style={{width:`${progressBarPercentage}`+'%'}}>{testProgressBar}/6</div>
      </div> */}
    { testProgressBar === 0 &&
      <>
      <div className="bar">
        <img src={Flow1} alt="flow" />
      </div>
      </>
    }
    { testProgressBar === 1 &&
      <>
      <div className="bar">
        <img src={Flow2} alt="flow" />
      </div>      </>
    }
    { testProgressBar === 2 &&
      <>
      <div className="bar">
        <img src={Flow3} alt="flow" />
      </div>      </>
    }
    { testProgressBar === 3 &&
      <>
      <div className="bar">
        <img src={Flow4} alt="flow" />
      </div>      </>
    }
    { testProgressBar === 4 &&
      <>
      <div className="bar">
        <img src={Flow5} alt="flow" />
      </div>      </>
    }
    { testProgressBar === 5 &&
      <>
      <div className="bar">
        <img src={Flow6} alt="flow" />
      </div>      </>
    }
      {/* <div className="command">click on any one of the options</div> */}
    </>
  )
}

//https://codepen.io/jenning/pen/bQvqMm
//https://codepen.io/cassidoo/pen/wEXQaG
//https://codepen.io/peruvianidol/pen/NLMvqO
