import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { isIE } from 'react-device-detect';
// import { isEdge } from 'react-device-detect';
// import { isFirefox } from 'react-device-detect';
// import { isOpera } from 'react-device-detect';
// import { isChrome } from 'react-device-detect';


ReactDOM.render(
  <React.StrictMode>
    <div>
      {isIE ? <div> <h3>CogSmart認知試験にアクセスいただきありがとうございます。</h3>
        <p>大変恐縮ですが、現在ご利用のブラウザは対応しておりません。下記の推奨ブラウザでのアクセスをお願いいたします。</p>
        <ul>
          <li><a href="https://www.google.com/intl/ja/chrome/">Google Chrome</a></li>
          <li><a href="https://www.mozilla.org/ja/firefox/new/">FireFox</a></li>
          <li><a href="https://support.apple.com/ja_JP/downloads/safari">Safari</a></li>
        </ul>
        <p>等</p> </div> :
      <div>
        <App />
      </div>
      }
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
